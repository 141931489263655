import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import PrivateHeader from "../components/PrivateHeader";
import { Navigate, useNavigate } from "react-router-dom";
import { AUTH_SESSION_ONSITE_CONSENT_DISPLAYED, AUTH_SESSION_ONSITE_SHARE_CODE, AUTH_SESSION_ONSITE_SPECIAL_AUDITION_CONSENT } from "../constants";
import { useAuth } from "../providers/AuthProvider";
import ConsentImage from './../icons/consent-signed.svg';

const ConsentSubmitted = () => {
    const isDisplayedAlready = localStorage.getItem(AUTH_SESSION_ONSITE_CONSENT_DISPLAYED);
    const profileSessionId = localStorage.getItem(AUTH_SESSION_ONSITE_SPECIAL_AUDITION_CONSENT);
    const shareCode = localStorage.getItem(AUTH_SESSION_ONSITE_SHARE_CODE);

    useEffect(() => {
        localStorage.setItem(AUTH_SESSION_ONSITE_CONSENT_DISPLAYED, true);
    }, []);

    if (isDisplayedAlready || !profileSessionId || !shareCode)
        window.location.replace('https://www.immmodels.com');

    if (isDisplayedAlready || !profileSessionId || !shareCode) return <></>

    return (
        <div style={{ paddingTop: 32, display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 32px)' }}>
            <div className="page-wrap" style={{ flexGrow: 1 }}>

                <div className="flex items-center flex-col align-center justify-center" style={{ marginTop: '4rem', padding: 24 }}>
                    <img src={ConsentImage} width={300} />
                    <h3 className="text-slate-700 text-center" style={{ fontSize: 20 }}>Thank you for providing your consent! <br />Please share this code with IMM Team: {shareCode}</h3>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ConsentSubmitted;
