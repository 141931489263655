import React from "react";
import { useAuth } from "../providers/AuthProvider";

const AuditionInfo = () => {
    const { user } = useAuth();
    const { agentId, agentName, profileName, sessionId } = user || {};
    return (
        <>
            <h4>MODEL INFO</h4>
                <ul>
                    <li><p>Name: <strong>{profileName}</strong></p></li>
                    <li><p>Profile ID: {sessionId}</p></li>
                    <li><p>Jobe Role: Virtual Fashion Model (Part Time)</p></li>
                </ul>

                <h4>PHOTOGRAPHER INFO</h4>
                <ul>
                    <li><p>Name: <strong>{agentName}</strong></p></li>
                    <li><p>Job ID: {agentId}</p></li>
                    <li><p>Designation: Associate Photographer, IMM Partner</p></li>
                </ul>

                <h4>AUDITION INFO</h4>
                <ul>
                    <li><p>Mode: <strong>Onsite</strong></p></li>
                    <li><p>Duration: 6HRS</p></li>
                    <li><p>Round: Round 2, Fashion Model</p></li>
                    <li><p>Prev Result(s):</p></li>
                    <ul style={{ paddingLeft: 12}}>
                        <li>
                            <p>Profile - Virtual - Pass Default</p>
                        </li>
                        <li><p>Round 1 - Virtual - Pass L2</p></li>
                        <li><p>Round 2 - Virtual - Fail L4</p></li>
                        <li><p>Round 2 - OnSite - In Progress</p></li>
                    </ul>
                </ul>
        </>
    )
}

export default AuditionInfo;
