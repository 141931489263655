import React, { useState } from "react";

import { Collection, UserStatus, getJobId } from "../constants";
import useMailActions from "../hooks/useMailActions";


const getUserStatus = (statusCode) => {
    switch(statusCode) {
        case UserStatus.SHORTLISTED:
            return 'Shortlisted';
        case UserStatus.SKYPE_RECEIVED:
            return 'Skype Received';
        case UserStatus.PROFILE_ID_SENT:
            return 'Profile Id Sent';
        case UserStatus.PROFILE_ACTIVATED:
        case UserStatus.ID_VERIFICATION_REQ_SENT:
            return 'Device Configured';
        case UserStatus.AUDITION_INVITE:
            return 'Audition Invite Sent';
        case UserStatus.ROUND_1_SELECT:
            return 'Round 1 Select';
        default:
            return 'New'
    }
}

const UserRow = ({ index, user, columns }) => {
    const [showActions, setShowActions] = useState(false);
    const { loading, sendAuditionInvite, sendAuditionShortlist, updateStatus, sendIdVerifyReq, sendProfileId, sendRound1Select, sendSkypeReceived, sendFashionInvite, sendFashionPortfolioReq } = useMailActions(user);

    const toggleActions = () => {
        setShowActions(!showActions);
    }

    const GetTd = (idx, key) => {
        switch (key) {
            case 'index':
                return (
                    <td key={idx}>
                        {index}
                    </td>
                )
            case 'name':
                return (
                    <td key={idx}>
                        {user[key]}
                        {user?.['isNew'] && <span title={new Date(user?.['createdDate']).toGMTString()} className="new-tag">New</span>}
                    </td>
                )
            case 'portfolio':
                const instaLink = user['instagram']?.includes('instagram.com') ? user['instagram'] : user['instagram'] && `https://instagram.com/${user['instagram']}`;
                return (
                    <td key={idx} title={user[key]}>
                        <div className="portfoio-links">
                            <a className="portfolio-link" href={user[key]} target="_blank" rel="noopener noreferrer">Portfolio</a>
                            &nbsp;
                            <a className="portfolio-link" href={instaLink} target="_blank" rel="noopener noreferrer">Insta</a>
                        </div>
                    </td>
                )
            case 'age':
                return (
                    <td key={idx}>
                        {user[key]}/{user['gender']?.substr(0, 1)}/{user['martialStatus']}
                    </td>
                )
            case 'jobId':
                return (
                    <td key={idx} title={getJobId(user.jobId)?.name}>
                        {getJobId(user.jobId)?.shortName}
                    </td>
            )
            case 'country':
                return (
                    <td key={idx}>
                        {user?.['city']},
                        {user[key]}
                    </td>
                )
            case 'action':
                return (
                    <td key={idx} onClick={toggleActions} className="user-actions">
                        <span>Show Actions</span>
                        {showActions && (
                            <>
                                <button disabled={loading === UserStatus.SHORTLISTED} onClick={sendAuditionShortlist}>{loading === UserStatus.SHORTLISTED ? 'Submitting': 'Send Shortlist'}</button>
                                <button disabled={loading === UserStatus.PROFILE_ID_SENT} onClick={sendProfileId}>{loading === UserStatus.PROFILE_ID_SENT ? 'Submitting': 'Send Profile ID'}</button>
                                <button disabled={loading === UserStatus.SKYPE_RECEIVED} onClick={sendSkypeReceived}>{loading === UserStatus.SKYPE_RECEIVED ? 'Submitting': 'Send Skype Recieved'}</button>
                                <button disabled={loading === UserStatus.PROFILE_ACTIVATED} onClick={sendIdVerifyReq}>{loading === UserStatus.PROFILE_ACTIVATED ? 'Submitting': 'Send ID Verify Req'}</button>
                                <button disabled={loading === UserStatus.AUDITION_INVITE} onClick={sendAuditionInvite}>{loading === UserStatus.AUDITION_INVITE ? 'Submitting': 'Send Audition Invite'}</button>
                                <button disabled={loading === UserStatus.ROUND_1_SELECT} onClick={sendRound1Select}>{loading === UserStatus.ROUND_1_SELECT ? 'Submitting': 'Send Round 1 Select'}</button>
                                <button disabled={loading === UserStatus.MODEL_INVITE_SENT} onClick={sendFashionInvite}>{loading === UserStatus.MODEL_INVITE_SENT ? 'Submitting': 'Send Fashion Model Invite'}</button>
                                <button disabled={loading === UserStatus.MODEL_PORTFOLIO_REQ} onClick={sendFashionPortfolioReq}>{loading === UserStatus.MODEL_PORTFOLIO_REQ ? 'Submitting': 'Send Fashion Portfolio Req'}</button>
                                <button onClick={() => updateStatus(UserStatus.MODEL_INVITE_SENT, Collection.Jobs)}>Mark Shortlist</button>
                            </>
                        )}
                    </td>
                )
            case 'status':
                return (
                    <td key={idx}>
                        <span className="user-status">
                        {getUserStatus(user[key])}
                        </span>
                    </td>
                )
            default: 
                return <td key={idx}>{user[key]}</td>
        }
    }

    return (
        <tr className="user-data-row">
            {columns.map((col, idx) => GetTd(idx, col.field, user))}
        </tr>
    )
}

export default UserRow;