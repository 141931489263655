import React from "react";
import Header from './Header';
import { Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import UserProvider from "../providers/UserProvider";

const AdminLayout = () => {
    return (
        <>
            <div className="admin-main" style={{ padding: 48 }}>
                <UserProvider>
                    <Toaster toastOptions={{ duration: 3000, style: { fontSize: 14, fontWeight: 500 }, position: 'top-center' }} />
                    <Outlet />
                </UserProvider>
            </div>
        </>
    )
}

export default AdminLayout;