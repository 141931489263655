// import { splitIntoEqualArray } from '../utils';
import AuditionImages from './../mock/auditionImages.json';
import CoupleImages from './coupleImages.json';
import CoupleFemaleImages from './coupleFemaleImages.json';

const { bikini: coupleBikini, casual: coupleCasual, topless: coupleTopless, nude: coupleNoOutfit } = CoupleImages?.reduce((acc, cur) => {
    return {
        ...acc,
        [cur?.outfit]: [
            ...(acc?.[cur?.outfit] ?? []),
            cur?.image,
        ]
    }
}, {})

const { bikini: coupleFemaleBikini, casual: coupleFemaleCasual, topless: coupleFemaleTopless } = CoupleFemaleImages?.reduce((acc, cur) => {
    return {
        ...acc,
        [cur?.outfit]: [
            ...(acc?.[cur?.outfit] ?? []),
            cur?.image,
        ]
    }
}, {})

const splitIntoEqualArray = (arr, aryCount = 3) => {
    // Calculate the base size of each part
    const baseSize = Math.floor(arr.length / aryCount);
    const remainder = arr.length % aryCount;

    const result = [];
    for (let i = 0; i < aryCount; i++) {
        // Determine the start and end indices for each part
        const start = i * baseSize;
        const end = start + baseSize + (i === aryCount - 1 ? remainder : 0);
        result.push(arr.slice(start, end));
    }
    return result;
}

const [coupleCasual1, coupleCasual2, coupleCasual3] = splitIntoEqualArray(coupleCasual, 3);
const [coupleBikini1, coupleBikini2, coupleBikini3] = splitIntoEqualArray(coupleBikini, 3);

// const coupleCasual = CoupleImages?.filter(imageObj => imageObj?.outfit === 'casual');
// const coupleBikini = CoupleImages?.filter(imageObj => imageObj?.outfit === 'bikini');
// const coupleTopless = CoupleImages?.filter(imageObj => imageObj?.outfit === 'topless');
// const coupleNoOutfit = CoupleImages?.filter(imageObj => imageObj?.outfit === 'nude');

export const TYPE_COUPLE_ACTIVITY = 'coupleActivity';
export const TYPE_COUPLE_FEMALE_ACTIVITY = 'femaleCoupleActivity';

export const onsiteInfo = {
    "default": {
        "totalSessions": 13,
        "sessions": [
            {
                "order": 1,
                "key": "casualSolo",
                "title": "Individual Model - Casual Prop (Variant 1)",
                "participation": "solo",
                "type": "casual",
                "images": AuditionImages.solo_c1
            },
            {
                "order": 2,
                "key": "portfolio",
                "title": "Portfolio Fitness Round",
                "type": "fitness",
                "participation": "solo",
                "images": AuditionImages.portfolio,
            },
            {
                "order": 3,
                "key": "bikiniSolo",
                "title": "Individual Model - Bikini Prop (Variant 1)",
                "participation": "solo",
                "type": "bikini",
                "images": AuditionImages.solo_b1,
            },
            {
                "order": 4,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 1)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual1,
            },
            {
                "order": 5,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 1)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual1,
            },
            {
                "order": 6,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 2)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual2,
            },
            {
                "order": 7,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 3)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual3,
            },
            {
                "order": 8,
                "key": "bikiniCouple",
                "title": "Couple - Bikini Prop (Variant 1)",
                "participation": "couple",
                "type": "bikini",
                "images": coupleBikini1,
            },
            {
                "order": 9,
                "key": "bikiniCouple",
                "title": "Couple - Bikini Prop (Variant 2)",
                "participation": "couple",
                "type": "bikini",
                "images": coupleBikini2,
            },
            {
                "order": 10,
                "key": "bikiniCouple",
                "title": "Couple - Bikini Prop (Variant 2)",
                "participation": "couple",
                "type": "bikini",
                "images": coupleBikini3,
            },
            {
                "order": 11,
                "key": "toplessCouple",
                "title": "Couple - Topless Prop (Single Variant)",
                "participation": "couple",
                "type": "topless",
                "images": coupleTopless,
            },
            {
                "order": 12,
                "key": "nooutfitCouple",
                "title": "Couple - Natural No-Outfit Variant",
                "participation": "couple",
                "type": "nooutfit",
                "images": coupleNoOutfit,
            },
            {
                "order": 13,
                "key": "toplessSolo",
                "title": "Solo - Topless Outfit",
                "participation": "solo",
                "type": "topless",
                "images": AuditionImages.solo_t1,
            }
        ]
    },
    "702884": {
        "totalSessions": 13,
        "sessions": [
            {
                "order": 1,
                "key": "casualSolo",
                "title": "Individual Model - Casual Prop (Variant 1)",
                "participation": "solo",
                "type": "casual",
                "images": AuditionImages.solo_c3
            },
            {
                "order": 2,
                "key": "portfolio",
                "title": "Portfolio Fitness Round",
                "type": "fitness",
                "participation": "solo",
                "images": AuditionImages.portfolio,
            },
            {
                "order": 3,
                "key": "bikiniSolo",
                "title": "Individual Model - Bikini Prop (Variant 1)",
                "participation": "solo",
                "type": "bikini",
                "images": AuditionImages.solo_b3,
            },
            {
                "order": 4,
                "key": "coupleActivity",
                "title": "Couple Activity",
                "participation": "couple",
                "type": TYPE_COUPLE_ACTIVITY,
                "images": AuditionImages.couple_activity,
            },
            {
                "order": 5,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 1)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual1,
            },
            {
                "order": 6,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 2)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual2,
            },
            {
                "order": 7,
                "key": "casualCouple",
                "title": "Couple - Casual Prop (Variant 3)",
                "participation": "couple",
                "type": "casual",
                "images": coupleCasual3,
            },
            {
                "order": 8,
                "key": "bikiniCouple",
                "title": "Couple - Bikini Prop (Variant 1)",
                "participation": "couple",
                "type": "bikini",
                "images": coupleBikini1,
            },
            {
                "order": 9,
                "key": "bikiniCouple",
                "title": "Couple - Bikini Prop (Variant 2)",
                "participation": "couple",
                "type": "bikini",
                "images": coupleBikini2,
            },
            {
                "order": 10,
                "key": "bikiniCouple",
                "title": "Couple - Bikini Prop (Variant 3)",
                "participation": "couple",
                "type": "bikini",
                "images": coupleBikini3,
            },
            {
                "order": 11,
                "key": "toplessCouple",
                "title": "Couple - Topless Prop (Single Variant)",
                "participation": "couple",
                "type": "topless",
                "images": coupleTopless,
            },
            {
                "order": 12,
                "key": "nooutfitCouple",
                "title": "Couple - Natural No-Outfit Variant",
                "participation": "couple",
                "type": "nooutfit",
                "images": coupleNoOutfit,
            },
            {
                "order": 13,
                "key": "toplessSolo",
                "title": "Solo - Topless Outfit",
                "participation": "solo",
                "type": "topless",
                "images": AuditionImages.solo_t1,
            },
            {
                "order": 14,
                "key": "femaleCoupleActivity",
                "title": "Couple Activity - Female Couple",
                "participation": "couple",
                "type": TYPE_COUPLE_FEMALE_ACTIVITY,
                "images": AuditionImages.couple_female_activity,
            },
            {
                "order": 15,
                "key": "casualFemaleCouple",
                "title": "Female Couple - Casual Outfit",
                "participation": "couple",
                "type": "casual",
                "images": coupleFemaleCasual,
            },
            {
                "order": 16,
                "key": "bikiniFemaleCouple",
                "title": "Female Couple - Bikini Outfit",
                "participation": "couple",
                "type": "casual",
                "images": coupleFemaleBikini,
            },
            {
                "order": 17,
                "key": "toplessFemaleCouple",
                "title": "Female Couple - Topless Outfit",
                "participation": "couple",
                "type": "casual",
                "images": coupleFemaleTopless,
            }
        ]
    }
}

export const OutfitInfo = {
    'casualSolo': {
        'model': 'Mini Dress / Sleevefree Co-ords Top with Short Skirt. Avoid denims, sleeves and boy shorts'
    },
    'bikiniSolo': {
        'model': 'Well Fitted Bikini Top and Bottom'
    },
    'toplessSolo': {
        'model': 'Top: No-Outfit, Bottom: Short Skirt'
    },
    'nooutfitSolo': {
        'model': 'No outfit while covering with your hands'
    },
    'casualCouple': {
        'model': 'Mini Dress / Sleevefree Co-ords Top with Short Skirt. Avoid denims, sleeves and boy shorts',
        'partner': 'Casual Top and Loose Pants'
    },
    'bikiniCouple': {
        'model': 'Well Fitted Bikini Top and Bottom',
        'partner': 'Sleevefree / Tank Top and Loose Pants'
    },
    'toplessCouple': {
        'model': 'Top: No-Outfit, Bottom: Short Skirt',
        'partner': 'Top: No-Outfit, Bottom: Loose Pants'
    },
    'nooutfitCouple': {
        'model': 'Full Nudity with Partner Covering each other artistically',
        'partner': 'Full Nudity with Partner Covering each other artistically'
    },
    'casualFemaleCouple': {
        'model': 'Mini Dress / Sleevefree Co-ords Top with Short Skirt. Avoid denims, sleeves and boy shorts',
        'partner': 'Mini Dress / Sleevefree Co-ords Top with Short Skirt. Avoid denims, sleeves and boy shorts'
    },
    'bikiniFemaleCouple': {
        'model': 'Well Fitted Bikini Top and Bottom',
        'partner': 'Well Fitted Bikini Top and Bottom'
    },
    'toplessFemaleCouple': {
        'model': 'Top: No-Outfit, Bottom: Short Skirt',
        'partner': 'Top: No-Outfit, Bottom: Short Skirt / Well Fitted Bikini Top and Bottom'
    },
}