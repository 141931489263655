import React from "react";
import { useForm } from "react-hook-form";

const FitnessMeasurement = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    return (
        <div className="flex flex-col bg-white rounded-b-md pb-4">
            <p className="border-bottom-trans p-2 text-slate-600 text-center text-bold">Portfolio Measurement (in inches)</p>
            <div className="p-2 grid grid-cols-2 gap-2">
                <input className="border rounded-sm p-2 text-xs" type="text" id="shoulder" placeholder="Shoulder" {...register("shoulder")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="chest" placeholder="Chest" {...register("chest")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="bust" placeholder="Bust" {...register("bust")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="bustApex" placeholder="Bust Apex" {...register("bustApex")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="waist" placeholder="Waist" {...register("waist")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="hips" placeholder="Hips" {...register("hips")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="thighs" placeholder="Thighs" {...register("thighs")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="thighsKness" placeholder="Thighs to Kness - Inner" {...register("thighsKness")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="hipsToWaistInner" placeholder="Waist to Hips" {...register("hipsToWaistInner")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="hipsToWaistFront" placeholder="Torso" {...register("hipsToWaistFront")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="crotchDepth" placeholder="Crotch Depth" {...register("crotchDepth")} />
                <input className="border rounded-sm p-2 text-xs" type="text" id="crotchLength" placeholder="Crotch Length" {...register("crotchLength")} />
            </div>
        </div>
    )
}

export default FitnessMeasurement;
