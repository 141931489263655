import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import PrivateHeader from "../components/PrivateHeader";
import { useNavigate, useParams } from "react-router-dom";
import { AUTH_SESSION_ONSITE_SHARE_CODE, AUTH_SESSION_ONSITE_SPECIAL_AUDITION_CONSENT, ProfileIDMap } from "../constants";
import { useAuth } from "../providers/AuthProvider";

const OnsiteAuditionConsent = () => {

    const { profileId, profileSessionId } = useParams();

    const profile = ProfileIDMap?.get(profileId);

    const navigate = useNavigate();
    const { consent, user } = useAuth();

    const onSubmit = (_profile) => {
        localStorage.setItem(AUTH_SESSION_ONSITE_SPECIAL_AUDITION_CONSENT, profileSessionId);
        localStorage.setItem(AUTH_SESSION_ONSITE_SHARE_CODE, _profile.shareCode);
        navigate('/onsite/consent/success');
    }

    const isConsentProvided = localStorage.getItem(AUTH_SESSION_ONSITE_SPECIAL_AUDITION_CONSENT);

    if (isConsentProvided || !profile || profile.sessionId !== profileSessionId)
        window.location.replace('https://www.immmodels.com');

    if (isConsentProvided || !profile || profile.sessionId !== profileSessionId) return <></>;

    return (
        <div style={{ paddingTop: 32, display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 32px)' }}>
            <div className="page-wrap" style={{ flexGrow: 1 }}>
                <PrivateHeader minimal name={profile.name} welcomeText="I" diableLogout />

                <div className="consent-form" style={{ width: '80vw' }}>
                    <h3 style={{ fontWeight: 'bold' }}>Consent Form</h3>
                    <div className="consent-data">
                        <div className="consent-list">
                            <p style={{ marginBottom: 12 }}>
                                As a candidate preparing for the Onsite IMM audition, I hereby confirm my understanding and agreement to the following statements. This consent is required to confirm your Onsite Audition final approval process.
                            </p>

                            <div style={{ overflowY: 'scroll' }}>
                                <ul className="consent-items">
                                    <li>
                                        <h4>Accuracy of Information:</h4>
                                        <span>
                                            I consent to verify all required documents and personal details before attending the onsite audition and confirm that all my information is accurate and up-to-date.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Health & Wellness:</h4>
                                        <span>
                                            I confirm that I am in good health and energy for the onsite audition. I also consent to refrain from attending the onsite audition during my menstrual cycle, as I am aware that IMM prioritizes the well-being of its models and the potential impact of this on my performance.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Professional Conduct:</h4>
                                        <span>
                                            I consent to maintain the highest level of professionalism throughout the audition process and to complete each part with my full interest and capabilities.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Responsible Reimbursement:</h4>
                                        <span>
                                            I am aware of IMM’s investment in this audition and commit to ensuring that only essential expenses are reimbursed, avoiding any unnecessary costs.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Confidentiality Commitment:</h4>
                                        <span>
                                            I consent to keep all IMM data, audition materials, and related information strictly confidential. I understand that sharing, redistributing, or capturing images, videos, or screenshots of IMM or audition materials is strictly prohibited.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Timely Arrival & Preparedness:</h4>
                                        <span>
                                            I consent to arrive punctually and fully prepared with all required materials, outfits, and props as outlined in the audition guidelines. I understand that lateness or lack of preparation may impact my evaluation.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Respect for IMM Policies & Team:</h4>
                                        <span>
                                            I consent to respect all IMM staff and abide by IMM's audition policies, understanding that any failure to follow these guidelines could impact my eligibility.
                                        </span>
                                    </li>
                                    <li>
                                        <h4>Duration of Audition:</h4>
                                        <span>
                                            I consent and acknowledge that the audition may last between 6-8 hours, with a potential extension to an additional day depending on specific requirements and scenarios.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="submissions-validate" style={{ paddingTop: 32 }}>
                            <button onClick={() => onSubmit(profile)} id="submissions-submit" type="submit" className="submit">I CONSENT</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OnsiteAuditionConsent;
