import React from 'react'

const Footer = ({ hideBorder = false }) => {
    return (
        <div id="footer" style={{ width: '100%' }}>
            <div className="footer-elements" style={hideBorder ? { border: 'none' } : {}}>
                <div className="footer-element">
                    <a href="#">I Want To Be A Model</a>
                </div>
                <div className="footer-element">
                    <a href="https://www.immmodels.com/london/about/" target="_blank">About Us</a>
                </div>
                <div className="footer-element">
                    <a href="mailto:newfaces@immmodels.org" target="_blank">Email Us Here</a>
                </div>
                <div className="footer-element-mobile">
                    <a href="#">I Want To Be A Model</a>
                </div>
            </div>
            <a className="mediaslide-link" href="#" title="mediaslide model agency software">IMM Agency Group</a>
        </div>
    )
}

export default Footer;