import React from "react";
import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import { useAuth } from "../providers/AuthProvider";
import { Navigate, useNavigate } from "react-router-dom";
import { encryptHash } from "../utils";
import toast from "react-hot-toast";

const IMMLogin = () => {
    let navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();

    const { login, isAuthenticated } = useAuth();

    const showToast = msg => {
        toast(msg, { position: 'top-right' });
        return;
    }

    const onSubmit = (data) => {
        const agentPassword = encryptHash(data?.agentPassword);
        const profilePassword = encryptHash(data?.profilePassword);
        login({ ...data, agentPassword, profilePassword })
            .then(res => {
                console.log(res);
                if (res.status == 200) {
                    window.location.href = '/audition/consent';
                    // navigate('/audition/consent')
                }
                if (res.status === 401 && res.alert) {
                    showToast(res.alert)
                }
            })
    }

    if (isAuthenticated) {
        return (
            <Navigate to='/audition/consent' />
        )
    }

    return (
        <div style={{ paddingTop: 32, display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 32px)' }}>
            <div className="page-wrap" style={{ flexGrow: 1 }}>
                <h1 className="page-title" style={{ marginBottom: 30 }}>AUDITION LOGIN</h1>
                <div className="login-container">
                    <div className="login-wrap">
                        <div className="submissions-form">
                            <form id="applicationForm" onSubmit={handleSubmit(onSubmit)}>
                                <input type="text" autoComplete="off" id="profileId" placeholder="PROFILE ID" {...register("profileId", { required: true })} />
                                <input type="password" id="profilePassword" placeholder="PROFILE PASSWORD" {...register("profilePassword", { required: true })} />

                                <div className="scouted-question" style={{ paddingTop: 12 }}>
                                    <p>AGENT VERIFICATION</p>
                                    <span style={{ fontSize: 12, textAlign: 'center', display: 'block', paddingBottom: 16 }}>(Only required in case of Onsite Auditions)</span>
                                </div>

                                <input type="text" id="username" placeholder="AGENT USERNAME" {...register("username", { required: false })} />
                                <input type="text" id="agentId" placeholder="AGENT ID" {...register("agentId", { required: false })} />
                                <input type="password" id="agentPassword" placeholder="PASSWORD" {...register("agentPassword", { required: false })} />

                                <div className="submissions-validate" style={{ paddingTop: 32 }}>
                                    <button id="submissions-submit" type="submit" className="submit">LOGIN</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default IMMLogin;
