import React from "react";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, query, orderBy, updateDoc, deleteDoc } from 'firebase/firestore/lite';
import { getDatabase, ref as dbRef, push as dbPush, onValue } from 'firebase/database';

const FirebaseContext = React.createContext(null);

const firebaseConfig = {
    apiKey: "AIzaSyB_410Dd6JzVetTGhvMwtXM5FT2gOVlYaY",
    authDomain: "fashiondestination-af6e0.firebaseapp.com",
    projectId: "fashiondestination-af6e0",
    storageBucket: "fashiondestination-af6e0.appspot.com",
    messagingSenderId: "7583090243",
    appId: "1:7583090243:web:c7f1ca57a212029235f6f6",
    measurementId: "G-LFM0HRQPX9"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const realtimeDb = getDatabase(firebaseApp);

const FirebaseProvider = ({ children }) => {    

    const getCollection = async (collectionName) => {
        const collections = collection(db, collectionName);
        const q = query(collections, orderBy('name'));
        const collectionSnapshot = await getDocs(q);
        const list = collectionSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        return list;
    }

    const updateDocument = async (collectionName, docId, newDoc) => {
       const docRef = doc(db, collectionName, docId);
       return await updateDoc(docRef, newDoc);
    }

    const deleteDocument = async (collectionName, docId, newDoc) => {
        const docRef = doc(db, collectionName, docId);
        return await deleteDoc(docRef, newDoc);
    }

    const listenToMessages = (msgPath, callback) => {
        const messagesRef = dbRef(realtimeDb, msgPath);
        onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                callback(data);
            }
        });
    }

    const sendMessage = (msgPath, message) => {
        const messagesRef = dbRef(realtimeDb, msgPath);
        dbPush(messagesRef, message);
    }

    const sendAuditionMessage = (message, sessionId, senderId, senderName, type = 'chat') => {
        const msgPath = `messages/audition/${sessionId}`
        const msg = {
            senderId,
            sessionId,
            senderName,
            message,
            time: Date.now(),
            read: false,
            type
        }
        const messagesRef = dbRef(realtimeDb, msgPath);
        dbPush(messagesRef, msg);
    }

    return (
        <>
            <FirebaseContext.Provider value={{
                firebaseApp,
                updateDocument,
                getCollection,
                deleteDocument,
                listenToMessages,
                sendMessage,
                sendAuditionMessage,

            }}>
                {children}
            </FirebaseContext.Provider>
        </>
    )
}

export const useFirebase = () => React.useContext(FirebaseContext);
export default FirebaseProvider;
