import React, { useMemo, useState, useEffect } from "react";
import useRegistrations from "../hooks/useRegistrations";
import { useFirebase } from "./FirebaseProvider";
import { UserStatus } from "../constants";
import capitalize from 'capitalize';

import _orderBy from 'lodash.orderby';

const UserContext = React.createContext(null);

const UserProvider = ({ children }) => {
    const [filtered, setFiltered] = useState(null);
    const { registrations, jobs } = useRegistrations();
    const { getCollection } = useFirebase();
    const [query, setQuery] = useState('');

    useEffect(() => {   
        const mUsers = query
        ? registrations?.filter(user => {
            const uname = user?.name?.toLowerCase();
            const uemail = user?.email?.toLowerCase();
            const q = query?.trim()?.toLowerCase();
            return uname.includes(q) || uemail.includes(q)
        })
        : registrations;
        setFiltered(mUsers);
    }, [registrations, query])

    const updateUser = (id, newObj) => {
        console.log({ id, newObj })
        const mUsers = filtered?.map(user => user?.id === id ? { ...user, ...newObj } : user );
        setFiltered(mUsers);
    }

    const { all, foreigns, modelling, others, inProgress, indians, newUsers, femaleNewUsers, femaleContacted, shortlisted } = useMemo(() => {
        const { modelling, others, unique } = filtered?.reduce((acc, cur) => {
            const key = cur?.isModelling === true ? 'modelling' : 'others';
            const isExists = acc?.others?.find(u => u?.email === cur?.email);
            const inviteStatus = (key === 'others' && (cur?.status === UserStatus.NEW || cur?.status !== UserStatus.ID_VERIFICATION_REQ_SENT)) ? 0 : 1;    
            return {
                ...acc,
                [key]: [...acc?.[key], cur],
                unique: isExists ? acc?.unique : [...acc?.unique, cur],
            }
        }, { modelling: [], others: [], unique: []}) || {}

        const isFemaleNew = (u) => {
            const gender = u?.gender?.substr(0, 1)?.toLowerCase();
            const isFemale = gender === 'f' || gender === 'w';
            const isNew = u?.status === UserStatus.NEW || u?.status !== UserStatus.MODEL_INVITE_SENT;
            return u?.isModelling !== true && isFemale && isNew;
        }

        const isFemaleContacted = (u) => {
            const gender = u?.gender?.substr(0, 1)?.toLowerCase();
            const isFemale = gender === 'f' || gender === 'w';
            const isContacted = u?.status !== UserStatus.NEW || u?.status === UserStatus.MODEL_INVITE_SENT;
            return u?.isModelling !== true && isFemale && isContacted;
        }

        const filterDups = (list, actualList) => {
            const filteredUsers = list?.reduce((acc, cur) => {
                const actEmail = cur?.email?.replace(/[._]/g, '')?.toLowerCase();
                
                const isDuplicate = actualList?.find(u => {
                    const curEmail = u?.email?.replace(/[._]/g, '')?.toLowerCase();
                    return curEmail === actEmail && u?.status === UserStatus.MODEL_INVITE_SENT;
                });

                const isUserExist = acc?.find(u => {
                    const curEmail = u?.email?.replace(/[._]/g, '')?.toLowerCase();
                    return actEmail === curEmail
                });
                const canAdd = (isDuplicate || isUserExist);
                return canAdd ? acc : [ ...acc, cur ];
            }, []);

            return filteredUsers;
        }

        console.log({ o: _orderBy(modelling, ['createdDate'], ['desc']), modelling})
        return {
            all: _orderBy(filtered, ['createdDate', 'age'], ['desc']),
            modelling: _orderBy(modelling, ['createdDate'], ['desc']),
            others,
            newUsers: modelling?.filter(u => !u.status),
            femaleNewUsers: filterDups(others?.filter(isFemaleNew), others),
            femaleContacted: others?.filter(isFemaleContacted),
            shortlisted: modelling?.filter(u => u.status === UserStatus.SHORTLISTED),
            inProgress: modelling?.filter(u => u.status && u.status !== UserStatus.SHORTLISTED),
            indians: modelling?.filter(u => u?.country?.trim().toLowerCase()?.includes('india')),
            foreigns: modelling?.filter(u => !u?.country?.trim().toLowerCase()?.includes('india')),
        }
    }, [filtered])

    const getNewEmails = () => {
        const filteredUsers = femaleNewUsers?.reduce((acc, cur) => {
            const actEmail = cur?.email?.replace(/[._]/g, '')?.toLowerCase();
            const isDuplicate = others?.find(u => {
                const curEmail = u?.email?.replace(/[._]/g, '')?.toLowerCase();
                return curEmail === actEmail && u?.status === UserStatus.MODEL_INVITE_SENT;
            })
            const isUserExist = acc?.find(u => {
                const curEmail = u?.email?.replace(/[._]/g, '')?.toLowerCase();
                return actEmail === curEmail
            });
            return isDuplicate || isUserExist ? acc : [ ...acc, cur ];
        }, [])
        ?.filter(u => u?.email)
        ?.map(u => `${capitalize.words(u?.name)} <${u?.email}> ${u?.id}`)
        ?.slice(0, 20)
        ?.join('|')
        ?.trim();
        console.log(filteredUsers);
        // return "Ayushi Sharma <rgrazia102@gmail.com> f6TXHgvf9bXbqDGOuiie|Aadrika Sharma <rgrazia103@gmail.com> p91MX7SucOvHKusYn95m";
        return filteredUsers;
    }

    return (
        <>
            <UserContext.Provider value={{ users: all, modelling, others, foreigns, inProgress, indians, newUsers, femaleNewUsers, femaleContacted, shortlisted, setQuery, updateUser, getNewEmails }}>
                {children}
            </UserContext.Provider>
        </>
    )
}

export const useUser = () => React.useContext(UserContext);
export default UserProvider;