import { useState, useCallback } from "react";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { useAuth } from "../providers/AuthProvider";

// Initialize S3 Client
const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
});

// Helper function to convert Base64 to Uint8Array
const base64ToUint8Array = (base64) => {
    const base64Data = base64.replace(/^data:image\/\w+;base64,/, "");
    const binaryString = atob(base64Data);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray;
};

const getRandomNumber = () => {
    return Math.floor(Math.random() * (89999 - 60000 + 1)) + 60000;
};

// Custom hook for S3 upload
const useS3Upload = () => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const { user } = useAuth();
    const { audition } = user || {};

    const uploadToS3 = useCallback(async (base64Image) => {
        setUploading(true);
        setProgress(0);
        setError(null);

        const fileName = `img_${Date.now()}_${getRandomNumber()}.jpg`

        try {
            const uint8ArrayData = base64ToUint8Array(base64Image);
            const params = {
                Bucket: "imm-audition",
                Key: `assets/${audition?.profileId ?? 'common'}/${fileName}`,
                Body: uint8ArrayData,
                ContentType: "image/jpeg", // Set MIME type
                ACL: "public-read",        // Optional: set permissions
            };

            const upload = new Upload({
                client: s3,
                params: params,
                queueSize: 3,   // Parallel uploads
                partSize: 1024 * 1024 * 5,  // 5MB per part
            });

            upload.on("httpUploadProgress", (event) => {
                if (event.total) {
                    setProgress(Math.round((event.loaded / event.total) * 100));
                }
            });

            const response = await upload.done();
            setData(response);
            console.log("Successfully uploaded data to S3:", response);
        } catch (err) {
            setError(err);
            console.error("Error uploading data:", err);
        } finally {
            setUploading(false);
        }
    }, []);

    const uploadVideoToS3 = useCallback(async (videoBlob) => {
        const fileName = `video_${Date.now()}.webm`; // Change file name as needed
        const params = {
            Bucket: "imm-audition",
            Key: `assets/${audition?.profileId ?? 'common'}/videos/${fileName}`,
            Body: videoBlob,
            ContentType: 'video/webm',
            ACL: "public-read",
        };

        try {
            const upload = new Upload({
                client: s3,
                params: params,
                queueSize: 3,   // Parallel uploads
                partSize: 1024 * 1024 * 10,  // 5MB per part
            });

            upload.on("httpUploadProgress", (event) => {
                if (event.total) {
                    setProgress(Math.round((event.loaded / event.total) * 100));
                }
            });

            const response = await upload.done();
            setData(response);
            console.log("Successfully uploaded data to S3:", response);
        } catch (err) {
            setError(err);
            console.error("Error uploading data:", err);
        } finally {
            setUploading(false);
        }
    }, []);

    return { uploadToS3, uploadVideoToS3, uploading, progress, error, data };
};

export default useS3Upload;