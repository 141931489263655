import React, { useMemo, useState } from "react";
import { encryptHash } from "../utils";
import { useNavigate } from "react-router-dom";
import { AUTH_AUD_SESSION_RECORD_ID, AUTH_SESSION_AUDITION_CONSENT, AUTH_SESSION_AUDITION_REVIEW, AUTH_SESSION_KEY, AUTH_SESSION_PROFILE_ID, AUTH_SESSION_RECORD_ID } from "../constants";
import useAuthorize from "../hooks/useAuthorize";

const AuthContext = React.createContext(null);

// export const API_BASE = `http://127.0.0.1:5002/fashiondestination-af6e0/us-central1/sendMail`;
export const API_BASE = `https://immmodels.org`;

const AuthProvider = ({ children }) => {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const { audSessionId } = useParams();
    const { isAuthorized, isAuthorizeLoading } = useAuthorize();

    const _invalidate = () => {
        localStorage.removeItem(AUTH_SESSION_KEY);
        localStorage.removeItem(AUTH_SESSION_PROFILE_ID);
        localStorage.removeItem(AUTH_SESSION_AUDITION_CONSENT);
        localStorage.removeItem(AUTH_SESSION_AUDITION_REVIEW);
        localStorage.removeItem(AUTH_SESSION_RECORD_ID);
    }

    const onSuccess = (response) => {
        if (response.status === 200) {
            const { profileId, name: profileName, agentName, agentId, sessionRecordId, audSessionId, agentLogin, audition, isAdmin } = response.user || {};
            const authData = {
                sessionId: profileId,
                sessionHash: encryptHash(profileId),
                profileName,
                agentName,
                agentId,
                sessionRecordId,
                audition,
                isAdmin,
                onsite: agentLogin === true,
            }
            localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(authData));
            localStorage.setItem(AUTH_SESSION_RECORD_ID, sessionRecordId);
            localStorage.setItem(AUTH_SESSION_PROFILE_ID, profileId);
            localStorage.setItem(AUTH_AUD_SESSION_RECORD_ID, audSessionId);
        }
        return {
            ...response,
        };
    }

    const onAuthorise = () => {
    }

    const onError = (response, ...props) => {
        console.log(response, props);
        localStorage.removeItem(AUTH_SESSION_KEY);
        localStorage.removeItem(AUTH_SESSION_PROFILE_ID);
        localStorage.removeItem(AUTH_SESSION_RECORD_ID);
    }

    const authorizeUser = async () => {
        const sessionId = localStorage.getItem(AUTH_SESSION_RECORD_ID);
        if (!sessionId) {
            return null;
        }
        setLoading(true);
        return fetch(`${API_BASE}/api/authorize`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ sessionRecordId: sessionId  }) })
            .then(res => res.json())
            .catch(onError)
            .finally(() => setLoading(false));
    }

    const authedUser = useMemo(() => {
        const sessionInfo = localStorage.getItem(AUTH_SESSION_KEY);
        const profileId = localStorage.getItem(AUTH_SESSION_PROFILE_ID);
        const sessionId = localStorage.getItem(AUTH_SESSION_RECORD_ID);

        if (!!sessionInfo && !!profileId) {
            try {
                const sessionData = JSON.parse(sessionInfo);
                const { sessionId, sessionHash } = sessionData || {};
                const profileHash = encryptHash(profileId);
                if (profileHash === sessionHash && profileId === sessionId) {
                    return sessionData;
                }
            } catch (e) {
                console.log('Something went wront', e);
                return false;
            }
        }
        // const authData = authorizeUser().then(d => console.log(d)).catch(e => console.log(e));
        // console.log("Auth Date", authData);
        // return !!authData;
    }, []);

    const login = ({ profileId, profilePassword, agentId, agentPassword, username }) => {
        setLoading(true);
        return fetch(`${API_BASE}/api/authenticate`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ profileId, profilePassword, agentId, agentPassword, username }) })
            .then(res => res.json())
            .then(onSuccess)
            .catch(onError)
            .finally(() => setLoading(false));
    }

    const logout = () => {
        _invalidate();
        // navigate('/audition/login');
        window.location.href = '/audition/login';
    }

    const consent = localStorage.getItem(AUTH_SESSION_AUDITION_CONSENT) === 'true';
    const reviewed = localStorage.getItem(AUTH_SESSION_AUDITION_REVIEW) === 'true';

    if (isLoading || isAuthorizeLoading) return <></>

    return (
        <>
            <AuthContext.Provider value={{
                isAuthenticated: isAuthorized,
                user: authedUser,
                login,
                logout,
                isLoading,
                consent,
                reviewed,
                audSessionId: localStorage.getItem(AUTH_AUD_SESSION_RECORD_ID),
            }}>
                {children}
            </AuthContext.Provider>
        </>
    )
}

export const useAuth = () => React.useContext(AuthContext);
export default AuthProvider;