import AuditionImages from './mock/auditionImages.json';

export const Collection = {
    'Registrations': 'registrations',
    'Jobs': 'jobs'
}

export const UserStatus = {
    'NEW': 0,
    'SHORTLISTED': 1,
    'SKYPE_RECEIVED': 2,
    'PROFILE_ID_SENT': 3,
    'PROFILE_ACTIVATED': 4,
    'AUDITION_INVITE': 5,
    'ROUND_1_SELECT': 6,
    'MODEL_PORTFOLIO_REQ': 7,
    'ID_VERIFICATION_REQ_SENT': 8,
    'MODEL_INVITE_SENT': 10,
}

export const MailTypes = {
    'ModelShortlisted': 1,
    'ModelSkypeRecieved': 2,
    'ProfileId': 3,
    'AuditionInvite': 5,
    'AuditionRound1Select': 6,
    'FashionPortfolioRequest': 7,
    'IDVerificationRequest': 8,
    'IDActivationRequest': 9,
    'FashionModelInvite': 10,
}

const JOB_LIST = {
    imm5210: {
        id: 2,
        shortName: 'DE',
        name: 'Data Entry / Content Writing and Copywriting (Part-time - Work From Home)',
        code: 'IMM5210',
    },
    imm5776: {
        id: 3,
        shortName: 'DM',
        name: 'Digital Marketing (Part-time - Work From Home)',
        code: 'IMM5776',
    },
    imm5216: {
        id: 4,
        shortName: 'VA',
        name: 'Virtual Assistance (Part-time - Work From Home)',
        code: 'IMM5216',
    },
    immt0012: {
        id: 5,
        shortName: 'OT',
        name: 'Online Teaching and Tutoring (Part-time - Work From Home)',
        code: 'IMMT0012',
    },
    imm5331: {
        id: 6,
        shortName: 'VHRC',
        name: 'Virtual Human Resource Consultant (Part-time - Work From Home)',
        code: 'IMM5331',
    },
    imm5025: {
        id: 7,
        shortName: 'FEBE',
        name: 'Front-end / Back-end Engineer (Part-time - Work From Home)',
        code: 'IMM5025',
    },
    imm5021: {
        id: 8,
        shortName: 'QA',
        name: 'Virtual QA Engineer (Manual/Automation) (Part-time - Work From Home)',
        code: 'IMM5021',
    },
    imm4222: {
        id: 9,
        shortName: 'VSE',
        name: 'Virtual Support Engineer (Part-time - Work From Home)',
        code: 'IMM4222',
    },
    imm5213: {
        id: 10,
        shortName: 'GO',
        name: 'Senior Go Developer (Full-time - Work From Home)',
        code: 'IMM5213',
    },
    imm4773: {
        id: 11,
        shortName: 'LDA',
        name: 'Lead Digital Artist (Full-time - Work From Home)',
        code: 'IMM4773',
    },
    imm4214: {
        id: 12,
        shortName: 'CW',
        name: 'Content Writer (Part Time & Full-time - Work From Home)',
        code: 'IMM4214',
    },
    imm4240: {
        id: 13,
        shortName: 'FD',
        name: 'Fashion Designer (Part Time & Full-time - Work From Home)',
        code: 'IMM4240',
    },
};

export const getJobId = (jobRefId) => {
    const defaultJobRole = { id: 1, name: 'Virtual Fashion Model', shortName: 'FM' }
    if (!jobRefId) return defaultJobRole;
    return JOB_LIST?.[jobRefId] ?? defaultJobRole;
}

export const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const AuditionIndexMap = {
    // 0: 'solo_c1',
    // 1: 'solo_t1',
    // 2: 'solo_b1',
    // 3: 'solo_b2',
    // 4: 'solo_c2',
    // 5: 'couple_s1',
    0: 'ff',
    1: 'portfolio',
    2: 'bikini',
    3: 'couple_activity',
    4: 'couple_s1',
    5: 'solo_t1',
}

export const AuditionMap = {
    'ff': {
        progess: 1,
        steps: 6,
        title: 'Individual Model - Casual Prop (Variant 1)',
        images: AuditionImages?.ff,
        type: 'casual',
        fkey: 'ff',
    },
    'portfolio': {
        progess: 2,
        steps: 6,
        title: 'Portfolio Fitness Round',
        images: AuditionImages?.portfolio,
        type: 'fitness',
        fkey: 'fitness',
    },
    'bikini': {
        progess: 3,
        steps: 6,
        title: 'Individual Model - Bikini Prop (Variant 1)',
        images: AuditionImages?.ff_bikini,
        type: 'bikini',
        fkey: 'ff',
    },
    // 'solo_b1': {
    //     progess: 3,
    //     steps: 6,
    //     title: 'Individual Model - Bikini Prop (Variant 1)',
    //     images: AuditionImages?.solo_b1,
    // },
    // 'solo_b2': {
    //     progess: 2,
    //     steps: 4,
    //     title: 'Individual Model - Bikini Prop (Variant 2)',
    //     images: AuditionImages?.solo_b2,
    //     type: 'bikini',
    //     fkey: 'solo_b2',
    // },
    'couple_activity': {
        progess: 4,
        steps: 6,
        title: 'Couple Activity',
        images: AuditionImages?.couple_activity,
        type: 'couple_activity',
        fkey: 'couple_activity',
    },
    'couple_s1': {
        progess: 5,
        steps: 6,
        title: 'Couple Session',
        images: AuditionImages?.couple_s2,
        type: 'couple',
        fkey: 'couple_s1',
    },
    'solo_t1': {
        progess: 6,
        steps: 6,
        title: 'Individual Model - Topless Prop (Variant 1)',
        images: AuditionImages?.ff_topless,
        type: 'topless',
        fkey: 'solo_t1',
    },
    // 'solo_c2': {
    //     progess: 5,
    //     steps: 4,
    //     title: 'Individual Model - Topless Prop (Variant 1)',
    //     images: AuditionImages?.solo_c2,
    // },
};

export const AUDITION_INSTRUCTIONS = {
    'casualSolo': [
        'Wear comfortable, fitted casual outfits that allow for movement.',
        'Replicate the pose with attention to body angles and alignment',
        'Maintain a relaxed facial expression that reflects ease and confidence',
        'Use your hands to create dynamic shapes or gestures.',
        'Engage with the environment, using props or surroundings creatively.',
        'Ensure your posture is open and inviting to convey confidence.',
        'Incorporate subtle movements; avoid being static in poses.'
    ],
    'bikiniSolo': [
        'Wear only a bikini or outfits of similar length.',
        'Emphasize fluidity in your transitions between poses',
        'Engage your core to enhance body posture',
        'Incorporate hand movements instead of remaining static.',
        'Use props or accessories to add interest to your poses',
        'Maintain consistent facial expressions and body positioning.'
    ],
    'toplessSolo': [
        'Go topless and use a short skirt for bottom',
        'Ensure your facial expressions and body alignment match the reference.',
        'Explore different angles to showcase your physique',
        'Incorporate natural body movement to convey confidence.',
        'Engage your core to enhance body posture.',
        'Focus on showcasing confidence and comfort in your movements.'
    ],
    'casualCouple': [
        'Maintain steady eye contact, staying close to enhance a sense of connection. Prioritize genuine expressions and natural movements.',
        'Avoid faking intimacy; instead, incorporate warm embraces, gentle hugs, and light kisses that feel authentic.',
        'Steer clear of rigid posing. Embrace fluid motions and gentle gestures, such as softly holding your partners hand or leaning in for a playful moment.'
    ],
    'bikiniCouple': [
        'Focus on creating fluid and sensual poses that exude confidence and connection. Utilize subtle touches like hand placement or leaning into your partner to create warmth.',
        'Maintain steady eye contact with a mix of playful and intimate expressions to make the poses engaging and lively.',
        'Avoid stiff movements. Incorporate light embraces, back-to-back stances, or playful gestures like a splash of water or a soft twirl to add spontaneity.'
    ],
    'toplessCouple': [
        'Highlight close body language and the natural contours of the poses, with your arms and positioning complementing each other.',
        'Maintain an intense yet inviting gaze with your partner, emphasizing mutual comfort and connection.',
        'Avoid abrupt actions; opt for soft, organic gestures like leaning into one another or a gentle embrace. Pay attention to details like hand placement and posture to create elegance.'
    ],
    'nooutfitCouple': [
        'Embrace the concept of natural nooutfit while showcasing emotions and raw artistic expression. Ensure poses remain professional, artistic, and aligned with the audition guidelines.',
        'Showcase symmetry and harmony in your poses. Focus on gestures that emphasize mutual support and artistry, such as entwined hands, seated back-to-back, or standing side-by-side with natural alignment.',
        'Engage with deep eye contact, relaxed smiles, or contemplative expressions that showcase trust and intimacy.',
    ],
    'portfolio': [
        'Only use a white tank top and white legging without innerwear. Any form of outfits other than tank top and leggind should be avoided in this fitness round including innerwears',
        'Photographer will measure the body measurements of Shoulder, Bust, Waist, Hips, Inner Thighs, Outer Thighs, Front Waist to Back Waist (Inner) and Height on each pose',
        'Stay relaxed on the pose until the photographer records the exact measurement.',
        'Measurement must be measured only by the alloted photographer'
    ],
    'couple_activity': [
        'Only use a white tank top and white legging without innerwear. Any form of outfits other than tank top and leggind should be avoided in this fitness round including innerwears',
        'Photographer will measure the body measurements of Shoulder, Bust, Waist, Hips, Inner Thighs, Outer Thighs, Front Waist to Back Waist (Inner) and Height on each pose',
        'Stay relaxed on the pose until the photographer records the exact measurement.'
    ]
}

export const COUPLE_ACTIVITIES = {
    0: { activity: 'Sit closer while facing each other with minimal or no gap!', timer: 15 },
    1: { activity: 'Sit closer while facing each other, Interlock fingers and Face each others lips. Make gentle movement with your hands during this time.', timer: 15 },
    1: { activity: 'Place a hand on each others shoulder and hold each others hip with other hand', timer: 15 },

    2: { activity: 'Model: Give your partner A brief, warm hug', timer: 15 },
    3: { activity: 'Male Partner: Give your partner A brief, warm tight hug', timer: 15 },

    4: { activity: 'Model: Share four areas on your partner that you particularly admire, such as their lips, eyes, cheeks, and neck.', timer: 10, input: true },
    5: { activity: 'Male Partner: Share four areas on your partner that you particularly admire, such as their lips, eyes, cheeks, and neck.', timer: 10, input: true },

    6: { activity: 'Model: Gently kiss the areas you specified each for 15 seconds, ensuring the kiss is smooth, slow, and passionate, while incorporating some tender movements.', timer: 20 },
    7: { activity: 'Male Partner: Gently kiss the areas you specified each for 15 seconds, ensuring the kiss is smooth, slow, and passionate, while incorporating some tender movements.', timer: 20 },

    8: { activity: 'Model: Gently hold your partner and give a light rub on your partner’s back..', timer: 20 },
    9: { activity: 'Male Partner: Gently hold your partner and give a light rub on your partner’s back..', timer: 20 },

    10: { activity: 'Lean against each other while seated', timer: 15 },
    11: { activity: 'Wrap an arm around the partner’s shoulder.', timer: 15 },

    12: { activity: 'Embrace each other tightly for 15 seconds', timer: 15 },
    13: { activity: 'Rubbing each other’s back slowly while holding each other', timer: 15 },

    14: { activity: 'One partner sits on the other’s lap with alternate kisses on cheek and neck', timer: 15 },
    15: { activity: 'Hold the each other’s face while kissing on the forehead', timer: 15 },

    16: { activity: 'One partner lifts the others leg while kissing passionately, creating a sense of intimacy', timer: 15 },
    17: { activity: 'Embrace tightly, with both partners keeping their chests together', timer: 15 },
    18: { activity: 'Each partner wraps an arm around the other’s waist while standing close', timer: 15 },
    19: { activity: 'A gentle kiss placed on the partner’s stomach while they hold each other', timer: 15 },
    20: { activity: 'Sit together on a chair, with one partner in the other’s lap, kissing and one partner holding the other partners chest and thighs.', timer: 15 },
    21: { activity: 'One partner hugs the other from behind, kissing their neck and shoulders while holding them tightly.', timer: 15 },
    22: { activity: 'Sit closely, face to face, and synchronize your breathing while sharing soft kisses and holding each other thighs, creating a deep emotional bond.', timer: 15 },
    23: { activity: 'Press chests together while looking into each other’s eyes and exchanging passionate kisses', timer: 15 },
    24: { activity: 'Maintain intense eye contact while kissing each other’s lips and mouths, allowing the kiss to linger and deepen, enhancing emotional connection.', timer: 15 },
    25: { activity: 'One partner sits on a chair while the other spins around them, kissing and holding their chest.', timer: 15 },
    26: { activity: 'One partner lifts the other while kissing passionately, holding them tightly at the waist.', timer: 15 },
}

export const COUPLE_FEMALE_ACTIVITIES = {
    0: { activity: 'Sit closer while facing each other with minimal or no gap!', timer: 15 },
    1: { activity: 'Sit closer while facing each other, Interlock fingers and Face each others lips. Make gentle movement with your hands during this time.', timer: 15 },
    1: { activity: 'Saying sweet or affectionate things to each other in a soft, intimate tone.', timer: 15 },

    2: { activity: 'Model: Give your partner A brief, warm hug', timer: 15 },
    3: { activity: 'Female Partner: Give your partner A brief, warm tight hug', timer: 15 },

    4: { activity: 'Model: Share four areas on your partner that you particularly admire, such as their lips, eyes, cheeks, and neck.', timer: 10, input: true },
    5: { activity: 'Female Partner: Share four areas on your partner that you particularly admire, such as their lips, eyes, cheeks, and neck.', timer: 10, input: true },

    6: { activity: 'Model: Gently kiss the areas you specified each for 15 seconds, ensuring the kiss is smooth, slow, and passionate, while incorporating some tender movements.', timer: 20 },
    7: { activity: 'Female Partner: Gently kiss the areas you specified each for 15 seconds, ensuring the kiss is smooth, slow, and passionate, while incorporating some tender movements.', timer: 20 },

    8: { activity: 'Model: Gently hold your partner and give a light rub on your partner’s back..', timer: 20 },
    9: { activity: 'Female Partner: Gently hold your partner and give a light rub on your partner’s back..', timer: 20 },

    10: { activity: 'Light, tender kisses along the neck, which can feel incredibly intimate.', timer: 15 },
    11: { activity: 'Gently kissing the palms, wrists, or fingertips, expressing care and affection.', timer: 15 },

    12: { activity: 'Embrace each other tightly for 15 seconds', timer: 15 },
    13: { activity: 'Rubbing each other’s back slowly while holding each other', timer: 15 },

    14: { activity: 'One partner sits on the other’s lap with alternate kisses on cheek and neck', timer: 15 },
    15: { activity: 'Giving soft, loving kisses on the forehead to convey tenderness and affection.', timer: 15 },

    16: { activity: 'Softly kissing or caressing each other’s chest, building sensual closeness.', timer: 15 },
    17: { activity: 'Lightly biting each other’s neck, ear, or lips as a playful way to express desire.', timer: 15 },
    18: { activity: 'Sensually brushing your lips along the back of their neck while holding them.', timer: 15 },
    19: { activity: 'A gentle kiss placed on the partner’s stomach while they hold each other', timer: 15 },
    20: { activity: 'Gently running your lips over their lips in a teasing way before kissing.', timer: 15 },
    21: { activity: 'One partner hugs the other from behind, kissing their neck and shoulders while holding them tightly.', timer: 15 },
    22: { activity: 'Sit closely, face to face, and synchronize your breathing while sharing soft kisses and holding each other thighs, creating a deep emotional bond.', timer: 15 },
    23: { activity: 'Gently tugging or pulling on each other’s hair while kissing or hugging.', timer: 15 },
    24: { activity: 'Maintain intense eye contact while kissing each other’s lips and mouths, allowing the kiss to linger and deepen, enhancing emotional connection.', timer: 15 },
    25: { activity: 'Slowly running your hands over their clothes or beneath clothing, building anticipation.', timer: 15 },
    26: { activity: 'Kissing gently along the inner thighs, an intimate area that can build tension and closeness.', timer: 15 },
}

export const ProfileIDMap = new Map([
    ['702884', { name: 'PUSHPA RANI', sessionId: '0376bf7b-2c13-4a94-8306-2b73716591ad', shareCode: '2b732c1374a94' }]
])

export const AUTH_SESSION_KEY = 'SESS_IMM_AUTH_INFO';
export const AUTH_SESSION_PROFILE_ID = 'SESS_IMM_PROFILE_ID';
export const AUTH_SESSION_AUDITION_CONSENT = 'SESS_IMM_AUDITION_CONSENT';
export const AUTH_SESSION_ONSITE_SPECIAL_AUDITION_CONSENT = 'SESS_IMM_ONSITE_SPECIAL_AUDITION_CONSENT';
export const AUTH_SESSION_ONSITE_SHARE_CODE = 'SESS_IMM_ONSITE_SHARE_CODE';
export const AUTH_SESSION_ONSITE_CONSENT_DISPLAYED = 'SESS_IMM_ONSITE_CONSENT_DISPLAYED';
export const AUTH_SESSION_AUDITION_REVIEW = 'SESS_IMM_AUDITION_REVIEW';
export const AUTH_SESSION_RECORD_ID = 'SESS_IMM_AUTH_SESSION_ID';
export const AUTH_AUD_SESSION_RECORD_ID = 'SESS_IMM_AUDITION_SESSION_ID';