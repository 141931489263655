
import CryptoJS from "crypto-js";
import { TYPE_COUPLE_ACTIVITY } from "./constants/onsiteInfo";

export function encryptWithSHA512(data) {
    const hashed = CryptoJS.HmacSHA512(data, process.env.REACT_APP_SECRET_KEY);
    const encryptedHex = hashed.toString(CryptoJS.enc.Hex);
    return encryptedHex;
}

export function encryptWithSHA256(data) {
    const hashed = CryptoJS.HmacSHA512(data, process.env.REACT_APP_SECRET_KEY);
    const encryptedHex = hashed.toString(CryptoJS.enc.Hex);
    return encryptedHex;
}

export function encryptHash(data) {
    const hashed = CryptoJS.HmacSHA512(data, process.env.REACT_APP_SECRET_KEY);
    const encryptedHex = hashed.toString(CryptoJS.enc.Hex);
    return encryptedHex;
}

export const getMsgPath = (sessionId) => `messages/audition/${sessionId}`;

export const splitIntoEqualArray = (arr, aryCount = 3) => {
    // Calculate the base size of each part
    const baseSize = Math.floor(arr.length / aryCount);
    const remainder = arr.length % aryCount;

    const result = [];
    for (let i = 0; i < aryCount; i++) {
        // Determine the start and end indices for each part
        const start = i * baseSize;
        const end = start + baseSize + (i === aryCount - 1 ? remainder : 0);
        result.push(arr.slice(start, end));
    }
    return result;
}

export const isCoupleSession = (session) => {
    const COUPLE_SESSIONS = [TYPE_COUPLE_ACTIVITY, 'casualCouple', 'bikiniCouple', 'toplessCouple', 'nooutfitCouple', 'casualFemaleCouple', 'bikiniFemaleCouple', 'toplessFemaleCouple'];
    return COUPLE_SESSIONS?.includes(session);
};