import React, { useEffect, useMemo, useState } from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import useDebounce from "../hooks/useDebounce";
import { useUser } from "../providers/UserProvider";
import UserTable from "./UserTable";
import useMailActions from "../hooks/useMailActions";

const columns = [
    { headerName: '#', field: 'index' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Email', field: 'email' },
    { headerName: 'Age', field: 'age' },
    { headerName: 'Country', field: 'country' },
    { headerName: 'Job Role', field: 'jobId' },
    { headerName: 'Portfolio', field: 'portfolio' },
    { headerName: 'Laptop', field: 'laptop' },
    { headerName: 'Status', field: 'status', width: 100 },
    { headerName: 'Actions', field: 'action', width: 180 },
]

const JobPortal = () => {
    const [query, setFilterQuery] = useState('');
    const debouncedQuery = useDebounce(query, 500);
    const { sendFashionBulkInvite } = useMailActions(null);
    const { users, modelling, others, indians, newUsers, femaleNewUsers, femaleContacted, shortlisted, inProgress, foreigns, setQuery } = useUser();

    useEffect(() => {
        setQuery(debouncedQuery);
    }, [debouncedQuery])

    const onQueryChange = (e) => {
        setFilterQuery(e.target.value)
    }

    return (
        <>
            <input className="input-search" type="text" placeholder="Search User..." onChange={onQueryChange} />
            
            <Tabs>
                <TabList>
                    <Tab>All <span>({users?.length})</span></Tab>
                    <Tab>Modelling <span>({modelling?.length})</span></Tab>
                    <Tab>Other Applicants <span>({others?.length})</span></Tab>
                    <Tab>New</Tab>
                    <Tab>Female New <span>({femaleNewUsers?.length})</span></Tab>
                    <Tab>Female Contacted <span>({femaleContacted?.length})</span></Tab>
                    <Tab>Shortlisted</Tab>
                    <Tab>In Progress</Tab>
                    <Tab>Indians</Tab>
                    <Tab>Foreigns</Tab>
                </TabList>
                <TabPanel>
                    <UserTable rows={users} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={modelling} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={others} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={newUsers} columns={columns} />
                </TabPanel>
                {/* Female New */}
                <TabPanel>
                    <button onClick={() => sendFashionBulkInvite(true)}>Send Bulk Invite</button>
                    <button onClick={() => sendFashionBulkInvite(false)}>Send Bulk Status Update</button>
                    <UserTable rows={femaleNewUsers} columns={columns} />
                </TabPanel>
                {/* Female Contacted */}
                <TabPanel>
                    <UserTable rows={femaleContacted} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={shortlisted} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={inProgress} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={indians} columns={columns} />
                </TabPanel>
                <TabPanel>
                    <UserTable rows={foreigns} columns={columns} />
                </TabPanel>
            </Tabs>
        </>
    )
}

export default JobPortal;
