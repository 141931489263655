import React from "react";
import UserRow from "./UserRow";

const UserTable = ({ columns, rows }) => {
    return (
        <>
            <table>
                <thead>
                    <tr>
                        {columns.map((col, idx) => <th key={idx} style={{ width: col.width }}>{col.headerName}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((user, index) => <UserRow index={index + 1} user={user} columns={columns} key={user.id} />)}
                </tbody>
            </table>
        </>
    )
}

export default UserTable;
