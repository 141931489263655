import React, { useState } from "react";
import { Collection, MailTypes, UserStatus, getJobId } from "../constants";
import toast from "react-hot-toast";
import { useFirebase } from "../providers/FirebaseProvider";
import { useUser } from "../providers/UserProvider";

const useMailActions = (user) => {
    const [loading, setLoading] = useState(false);
    const { updateDocument } = useFirebase();
    const { updateUser, getNewEmails } = useUser();

    const postMail = async (mailType, props, collection = Collection.Registrations) => {
        const { id, name, email } = user || {};
        const { emailList } = props || {};
        const body = {
            id,
            name,
            email,
            emailList,
            mailType,
            collection,
            ...props,
        }
        console.log('Posting Mail', body);
        // debugger;
        return await fetch('https://immmodels.org/api/send-mail', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    }

    const onSuccess = (id, newStatus, props) => {
        toast.success('Mail sent!');
        updateUser(id, { status: newStatus, ...props });
    }

    const onError = (err) => {
        console.log(err);
        toast.success('Error sending mail!');
    }

    const onFinally = () => {
        setLoading(false);
    }

    const _getJobId = (jobRefId) => getJobId(jobRefId)?.id;

    const sendAuditionShortlist = () => {
        setLoading(MailTypes.ModelShortlisted);
        postMail(MailTypes.ModelShortlisted)
            .then(() => onSuccess(user.id, MailTypes.ModelShortlisted))
            .catch(onError)
            .finally(onFinally)
    }

    const sendSkypeReceived = () => {
        setLoading(MailTypes.ModelSkypeRecieved);
        postMail(MailTypes.ModelSkypeRecieved)
            .then(() => onSuccess(user.id, MailTypes.ModelSkypeRecieved))
            .catch(onError)
            .finally(onFinally)
    }

    const sendProfileId = async () => {
        setLoading(MailTypes.ProfileId);
        postMail(MailTypes.ProfileId)
            .then(() => onSuccess(user.id, MailTypes.ProfileId))
            .catch(onError)
            .finally(onFinally)
    }

    const sendIdVerifyReq = () => {
        const jobId = _getJobId(user?.jobId);
        setLoading(MailTypes.IDVerificationRequest);
        postMail(MailTypes.IDVerificationRequest, { jobId })
            .then(() => onSuccess(user.id, MailTypes.IDVerificationRequest))
            .catch(onError)
            .finally(onFinally)
    }

    const sendFashionPortfolioReq = () => {
        const jobId = _getJobId(user?.jobId);
        setLoading(MailTypes.FashionPortfolioRequest);
        postMail(MailTypes.FashionPortfolioRequest, { jobId })
            .then(() => onSuccess(user.id, MailTypes.FashionPortfolioRequest))
            .catch(onError)
            .finally(onFinally)
    }

    const sendIdActivationReq = () => {
        setLoading(MailTypes.IDActivationRequest);
        postMail(MailTypes.IDActivationRequest)
            .then(() => onSuccess(user.id, MailTypes.IDActivationRequest))
            .catch(onError)
            .finally(onFinally)
    }

    const sendAuditionInvite = (props) => {
        setLoading(MailTypes.AuditionInvite);
        postMail(MailTypes.AuditionInvite, props)
            .then(() => onSuccess(user.id, MailTypes.AuditionInvite, props))
            .catch(onError)
            .finally(onFinally)
    }

    const sendRound1Select = () => {
        setLoading(MailTypes.AuditionRound1Select);
        postMail(MailTypes.AuditionRound1Select)
            .then(() => onSuccess(user.id, MailTypes.AuditionRound1Select))
            .catch(onError)
            .finally(onFinally)
    }

    const sendFashionInvite = () => {
        setLoading(MailTypes.FashionModelInvite);
        postMail(MailTypes.FashionModelInvite, { invited: 1 }, Collection.Jobs)
            .then(() => onSuccess(user.id, MailTypes.FashionModelInvite))
            .catch(onError)
            .finally(onFinally)
    }

    const sendFashionBulkInvite = (sendMail = true) => {
        const emailList = getNewEmails();
        setLoading(MailTypes.FashionModelInvite);
        postMail(MailTypes.FashionModelInvite, { emailList, invited: 1, sendMail }, Collection.Jobs)
            .then(() => {
                onSuccess();
                window.location.reload();
            })
            .catch(onError)
            .finally(onFinally)

    }

    const updateStatus = (status = UserStatus.SHORTLISTED, collection = Collection.Registrations) => {
        setLoading(status);
        updateDocument(collection, user.id, { status })
            .then(() => onSuccess(user.id, MailTypes.ModelShortlisted))
            .catch(onError)
            .finally(onFinally)
    }

    return {
        loading,
        updateStatus,
        sendProfileId,
        sendIdVerifyReq,
        sendRound1Select,
        sendSkypeReceived,
        sendFashionInvite,
        sendAuditionInvite,
        sendIdActivationReq,
        sendAuditionShortlist,
        sendFashionBulkInvite,
        sendFashionPortfolioReq,
    }
}

export default useMailActions;