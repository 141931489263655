import React, { useCallback, useEffect, useRef, useState } from "react";

import Webcam from "react-webcam";
import useS3Upload from "../hooks/useS3Upload";
import CheckedIcon from './../icons/badge.svg';
import { COUPLE_ACTIVITIES, COUPLE_FEMALE_ACTIVITIES } from "../constants";
import useSound from "use-sound";

import notifySound1 from './../sounds/notify-1.mp3';
import notifySound2 from './../sounds/notify-2.mp3';
import beep1 from './../sounds/beep-1.mp3';
import beep2 from './../sounds/beep-2.mp3';
import { OutfitInfo, TYPE_COUPLE_ACTIVITY, TYPE_COUPLE_FEMALE_ACTIVITY } from "../constants/onsiteInfo";
import { isCoupleSession } from "../utils";
import FitnessMeasurement from "./FitnessMeasurement";
const defaultPlaceholdURL = '/assets/images/img-placeholder.webp';

const WAIT_SECONDS = 15;
const CAPTURE_COUNT = 3; // Number of images to capture
const VIDEO_RECORD_SECONDS = 21;
const VIDEO_READY_SECONDS = 5;

const WebCapture = ({ moveNext, instructions, nextActivity, sessionType, showPrefInput }) => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [caCount, setCACount] = useState(0);
    let capturedSnaps = [];

    const { uploadToS3, uploadVideoToS3, uploading } = useS3Upload();
    const [playNotify] = useSound(notifySound1, { volume: 1, playbackRate: 1 });
    const [playBeep] = useSound(beep1, { volume: 1, playbackRate: 1 });

    const [auditionInfo, setAuditionInfo] = useState('RELAX');

    const [capturedImages, setCapturedImages] = useState(null);

    const [recording, setRecording] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);

    const startRecording = useCallback(() => {
        setRecording(true);
        setVideoBlob(null);

        // Get the stream from the webcam
        const stream = webcamRef.current.video.srcObject;
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm; codecs=vp8' });

        mediaRecorderRef.current = mediaRecorder;

        const chunks = [];

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                chunks.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, { type: 'video/mp4' });
            downloadVideoBlob(blob);
            uploadVideoToS3(blob);
            setVideoBlob(URL.createObjectURL(blob));
        };

        mediaRecorder.start();

        const timer = setTimeout(() => {
            setRecording(false);
            stopRecording();
            captureSnapshotAndPreview();
            clearTimeout(timer);
            setAuditionInfo('RELAX');
        }, VIDEO_RECORD_SECONDS * 1000)
    }, [webcamRef, uploadVideoToS3])

    const stopRecording = useCallback(() => {
        setRecording(false);
        mediaRecorderRef.current.stop();
    }, []);

    const downloadVideoBlob = (blob) => {
        if (blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `encrypted_data_imm_${Date.now()}.crypt`; // Specify the file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // Cleanup
            URL.revokeObjectURL(url);
        }
    }

    const downloadVideo = useCallback(() => {
        if (videoBlob) {
            const a = document.createElement('a');
            a.href = videoBlob;
            a.download = 'recorded-video.webm'; // Specify the file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // Cleanup
        }
    }, [videoBlob]);

    const stopAndDownload = () => {
        stopRecording();
        downloadVideo();
    }

    const captureSnapshotAndPreview = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        capturedSnaps.push(imageSrc);
        setCapturedImages((prevImages) => [...prevImages, imageSrc]);
        uploadToS3(imageSrc);
        playNotify();
        return imageSrc;
    }

    const capture = useCallback(() => {
        capturedSnaps = [];
        let captured = 0;
        let timerSecond = 0;
        setCapturedImages([]);
        setAuditionInfo('READY TO CAPTURE');

        const scheduleCapture = () => {
            const interval = setInterval(() => {

                if (WAIT_SECONDS >= timerSecond) {
                    console.log('Capture In', timerSecond);
                    setAuditionInfo(`CAPTURING IN ${WAIT_SECONDS - timerSecond}`);
                    playBeep();
                    timerSecond++;
                }

                if (timerSecond === WAIT_SECONDS + 1) {
                    console.log('TIMER EXPIRED')
                    setAuditionInfo(`RELAX`);
                    clearInterval(interval);
                    const imageSrc = captureSnapshotAndPreview();
                    captured += 1;

                    if (captured < CAPTURE_COUNT) {
                        if (captured === CAPTURE_COUNT - 1) {
                            setAuditionInfo('MAKE HAND MOVEMENTS ON YOUR PARTNER');
                            timerSecond = 0;

                            let videoTimer = 0;
                            const _interval = setInterval(() => {
                                if (videoTimer < VIDEO_READY_SECONDS) {
                                    playBeep();
                                    videoTimer++;
                                }
                                if (videoTimer === VIDEO_READY_SECONDS - 1) {
                                    startRecording();
                                    clearInterval(_interval);
                                }
                            }, 1000);
                            return;
                        }
                        timerSecond = 0;
                        scheduleCapture();
                    }
                }
            }, 1000);
        }

        scheduleCapture();
    }, [webcamRef, uploadToS3, playBeep, playNotify]);

    useEffect(() => {
        // playBeep();
        // playNotify();
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                if (isCoupleSession(sessionType))
                    capture()
            }

            if (event.shiftKey && event.key === 'Escape') {
                if (sessionType === TYPE_COUPLE_ACTIVITY) {
                    nextActivity();
                } else {
                    moveNext();
                }
            }
        };

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [sessionType]);

    // const nextActivity = () => {
    //     setCACount((prev, cur) => prev + 1)
    // }

    const isCoupleSess = isCoupleSession(sessionType);

    const outfitPrefs = OutfitInfo?.[sessionType] ?? OutfitInfo?.['casual']
    const CoupleActivities = sessionType === TYPE_COUPLE_FEMALE_ACTIVITY ? COUPLE_FEMALE_ACTIVITIES : COUPLE_ACTIVITIES;

    return (
        <>
            <div className="flex flex-col">
                {isCoupleSess && (
                    <div className="">
                        <Webcam
                            style={{ width: 'w-full' }}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            itemRef=""
                            screenshotQuality={1}
                            videoConstraints={{ height: { ideal: 1080 }, facingMode: 'user', }}
                            minScreenshotWidth={1440}
                            forceScreenshotSourceSize
                        />
                        <div className="flex bg-white p-2 font-sans capitalize">
                            <h5 className="text-center grow text-slate-600">{recording ? 'RECORDING' : auditionInfo}</h5>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            <div className="bg-white relative shadow-sm aspect-square">
                                <img src={capturedImages?.[0] ?? defaultPlaceholdURL} className="h-full w-full object-cover object-center" />
                            </div>
                            <div className="bg-white relative shadow-sm">
                                <img src={capturedImages?.[1] ?? defaultPlaceholdURL} className="h-full w-full object-cover object-center" />
                            </div>
                            <div className="bg-white relative shadow-sm">
                                <img src={capturedImages?.[2] ?? defaultPlaceholdURL} className="h-full w-full object-cover object-center" />
                            </div>
                        </div>
                    </div>
                )}

                {(sessionType !== TYPE_COUPLE_ACTIVITY && sessionType !== TYPE_COUPLE_FEMALE_ACTIVITY) && (
                    <div className={`grow bg-white shadow-sm mb-2 flex flex-col ${isCoupleSess ? 'mt-2' : 'rounded-t-md'}`}>
                        <h4 className="border-bottom-trans text-slate-600 p-2 text-center">Instructions</h4>
                        <div className="p-2 pt-3 text-sm">
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center">
                                    <img src={CheckedIcon} className="self-center mr-3 green-500" width={20} />
                                    {/* <p className="font-normal text-slate-500">Model Outfit :</p> */}
                                    <span className="font-serif text-slate-500 font-medium	text-normal text-xs">Model Outfit : {outfitPrefs?.model}</span>
                                </div>
                                {outfitPrefs?.partner && (
                                    <div className="flex pt-2 items-center">
                                        <img src={CheckedIcon} className="self-center mr-3 green-500" width={20} />
                                        {/* <p className="font-normal text-slate-500">Partner Outfit :</p> */}
                                        <span className="font-serif text-slate-500 font-medium	text-normal text-xs">Partner Outfit : {outfitPrefs?.partner}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-2" style={{ flexGrow: 0 }}>
                            {instructions?.map((instruction, idx) => (
                                <div key={idx} className="flex flex-row pb-3">
                                    <img src={CheckedIcon} className="self-center mr-3 green-500" width={20} />
                                    <p className="font-serif text-slate-500 font-medium	text-normal text-xs">{instruction}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {(sessionType === TYPE_COUPLE_ACTIVITY || sessionType === TYPE_COUPLE_FEMALE_ACTIVITY) && (
                    <div className={`grow bg-white shadow-sm mb-2 flex flex-col ${isCoupleSess ? 'mt-2' : ''}`}>
                        <h4 className="border-bottom-trans text-slate-600 p-2 text-center">COUPLE ACTIVITY GUIDE</h4>
                        <div className="p-2 grow">
                            <div className="flex flex-row pb-3">
                                <img src={CheckedIcon} className="self-center mr-3 green-500" width={20} />
                                <p className="font-serif text-slate-500 font-medium text-sm	text-normal">Couple activities form a crucial part of the audition, setting the stage for the couple posing session. They are essential for building expressions, boosting confidence in poses, and establishing comfort with your partner. Please follow each instruction closely and act accordingly.</p>
                            </div>
                            <p className="pl-8 font-serif text-slate-500 text-sm font-semibold">Duration: {CoupleActivities?.[caCount]?.timer} seconds</p>

                        </div>
                        {showPrefInput && (
                            <div className="p-2">
                                <input className="border rounded-sm p-2 text-xs" type="text" id="name" placeholder="Enter answer.." />
                            </div>
                        )}
                        <div className="flex rounded-md">
                            <button type="button" onClick={capture} className="flex flex-1 rounded-bl-md items-center justify-center border border-transparent bg-rose-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-rose-800">Capture / Re-Take</button>
                            <button type="button" onClick={nextActivity} className="flex flex-1 rounded-br-md items-center justify-center border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">Submit & Proceed</button>
                        </div>
                    </div>
                )}

                {sessionType === 'portfolio' && (
                    <FitnessMeasurement />
                )}

                {isCoupleSession(sessionType) && sessionType !== TYPE_COUPLE_ACTIVITY && (
                    <div className="flex rounded-md">
                        <button type="button" onClick={capture} className="flex flex-1 rounded-bl-md items-center justify-center border border-transparent bg-rose-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-rose-800">Capture / Re-Take</button>
                        <button type="button" onClick={moveNext} className="flex flex-1 rounded-br-md items-center justify-center border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">Submit & Proceed</button>
                    </div>
                )}
            </div>
        </>
    )
}

export default WebCapture;