import React, { useEffect, useMemo, useState } from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import ImageViewer from "react-simple-image-viewer";

import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import AuditionOverview from "../components/AuditionOverview";
import { AUDITION_INSTRUCTIONS, AuditionIndexMap, AuditionMap, COUPLE_ACTIVITIES, COUPLE_FEMALE_ACTIVITIES } from "../constants";
import PrivateHeader from "../components/PrivateHeader";
import { useAuth } from "../providers/AuthProvider";
import { Navigate } from "react-router-dom";
import { useFirebase } from "../providers/FirebaseProvider";
import useMessages from "../hooks/useMessages";
import WebCapture from "../components/WebCapture";
import { TYPE_COUPLE_ACTIVITY, TYPE_COUPLE_FEMALE_ACTIVITY, onsiteInfo } from "../constants/onsiteInfo";

export const AUDITION_KEY = 'imm_aud_key';
const MESSAGE_PATH = 'messages/fash_aud_chat_mode';
const TOTAL_SESSIONS = 6;

const AuditionInterview = () => {
    const [caCount, setCACount] = useState(0);
    const { consent, reviewed, logout, user: { audition } } = useAuth();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();
    let api;

    // WebCam
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const [showSlideshow, setShowSlideshow] = useState(false);
    // const [interviewKey, setInterviewKey] = useState(localStorage.getItem(AUDITION_KEY || 0));

    const { sessions, currentSession, currentSessionIndex, remainingCompletion, totalSessions, remainingSessions } = useMemo(() => {
        const { sessions } = onsiteInfo?.[audition?.profileId] ?? onsiteInfo?.['default'];
        const currentSession = +localStorage.getItem(AUDITION_KEY || 0);
        const remainingSessions = sessions?.length - currentSession - 1;
        return {
            sessions,
            currentSessionIndex: currentSession,
            currentSession: sessions?.[currentSession],
            totalSessions: sessions?.length,
            remainingSessions,
            remainingCompletion: Math.round((remainingSessions / sessions.length) * 100),
        }
    }, [onsiteInfo, audition]);

    const interviewKey = localStorage.getItem(AUDITION_KEY) || 0;
    const [isLastSlide, setIsLastSlide] = useState(false);

    const { listenToMessages, sendMessage } = useFirebase();
    const messages = useMessages(MESSAGE_PATH);


    const onMessageReceived = (msg) => {
        console.log('Msg from server', msg);
    }

    useEffect(() => {
        listenToMessages(MESSAGE_PATH, onMessageReceived)
    }, [listenToMessages])

    const nowShowing = currentSession;
    const sessionType = nowShowing?.key;
    const instructions = AUDITION_INSTRUCTIONS?.[sessionType ?? 'casual'];

    console.log({ sessions, sessionType, currentSession, remainingCompletion, totalSessions, remainingSessions });


    const onSubmit = (data) => {
        console.log(data);
    }

    const closeImageViewer = () => {

    }

    const onSlideChange = (section, origin, destination, direction, trigger) => {
        const { isLast } = destination || {};
        setIsLastSlide(isLast);
        setImgSrc(null);
    }

    const handleAuditionComplete = () => {
        if (+interviewKey < totalSessions - 1) {
            localStorage.setItem(AUDITION_KEY, +interviewKey + 1);
            window.location.reload();
        } else {
            logout();
        }
    }

    const handleOnCapture = (wcIndex) => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc({
            ...imgSrc,
            [wcIndex]: imageSrc,
        });
    }

    if (!consent) {
        return <Navigate to='/audition/consent' />
    }

    if (!reviewed) {
        return <Navigate to='/audition/home' />
    }

    const sendMsg = () => {
        sendMessage(MESSAGE_PATH, 'Acknowledged!')
    }

    const handleOnNext = () => {
        api.moveSlideRight();
    }

    const nextActivity = () => {
        setCACount((prev, cur) => {
            if (prev === 26 - 1) { setIsLastSlide(true) }
            return prev + 1
        })
    }

    const CoupleActivities = sessionType === TYPE_COUPLE_FEMALE_ACTIVITY ? COUPLE_FEMALE_ACTIVITIES : COUPLE_ACTIVITIES;
    console.log({ sessionType })
    return (
        <>
            <PrivateHeader minimal showDetails />
            <main className="main h-full onsite-wrap">
                <div className="grid grid-cols-12 gap-4 h-full onsite-wrap-cover">
                    <div className="grid-item col-span-2 bg-white rounded-md relative shadow-md p-4" style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                        <AuditionOverview
                            activeStep={currentSessionIndex}
                            isLastSlide={isLastSlide}
                            onAuditionComplete={handleAuditionComplete}
                            moveNext={handleOnNext}
                            sessions={sessions}
                        />
                    </div>
                    <div className="grid-item col-span-7 bg-white rounded-md relative shadow-md">
                        <ReactFullpage
                            keyboardScrolling={false}
                            scrollingSpeed={600}
                            waterEffect={false}
                            loopHorizontal={false}
                            responsiveHeight={400}
                            onSlideLeave={onSlideChange}
                            onScrollOverflow={() => console.log('On Overflow')}
                            render={({ state, fullpageApi }) => {
                                api = fullpageApi;
                                fullpageApi?.setKeyboardScrolling(true, 'left, right');
                                return (
                                    <ReactFullpage.Wrapper>
                                        <div className="section" id="section0">
                                            {nowShowing?.images?.map((image, idx) => (
                                                <div key={idx} className="slide" data-anchor={`slide${idx + 1}`}>
                                                    <img className="img-poses aspect-auto object-contain" src={`https://imm-audition.s3.ap-south-1.amazonaws.com/${image}`} />
                                                </div>
                                            ))}
                                        </div>
                                    </ReactFullpage.Wrapper>
                                );
                            }}
                        />
                        <div className="wm-wrap">
                            &nbsp;
                        </div>

                        {(sessionType === TYPE_COUPLE_ACTIVITY || sessionType === TYPE_COUPLE_FEMALE_ACTIVITY) && (
                            <div className="activity-wrap rounded-md items-center content-center justify-center">
                                <h4 className="text-white p-16 text-4xl uppercase text-center" style={{ lineHeight: '3.5rem', padding: '5rem' }}>{CoupleActivities?.[caCount]?.activity}</h4>
                            </div>
                        )}
                    </div>
                    <div className="grid-item col-span-3 gap-2 flex">
                        <WebCapture moveNext={handleOnNext} nextActivity={nextActivity} instructions={instructions} sessionType={sessionType} showPrefInput={COUPLE_ACTIVITIES?.[caCount]?.input === true} />
                    </div>
                </div>
            </main>
            <Footer hideBorder />
        </>
    )
}

export default AuditionInterview;
