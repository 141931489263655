import React, { useEffect, useState } from "react";
import { useFirebase } from "../providers/FirebaseProvider";

const getMillis = (dateObj, defaultMillis = 1699183800000) => {
    const { seconds, nanoseconds } = dateObj || {};
    const millis = seconds * 1000 + Math.round(nanoseconds / 1e6);
    const dateMillis = dateObj ? millis : defaultMillis;
    return dateMillis;
}

const useRegistrations = () => {
    const [registrations, setReg] = useState(null);
    const [jobs, setJobs] = useState(null);

    const { getCollection } = useFirebase();
    
    useEffect(() => {
        Promise.all([getCollection('registrations'), getCollection('jobs')])
            .then(data => {
                const [modelling, others] = data || [];
                const modellingRoles = modelling?.map(user => {
                    const m48Hours = 172800000;
                    const now = Date.now();
                    const createdDate = getMillis(user?.createdAt);
                    return {
                        ...user,
                        createdDate,
                        jobId: 'imm4577',
                        isModelling: true,
                        updatedDate: getMillis(user?.updatedAt),
                        isNew: now - createdDate <= m48Hours && user?.status == 0,
                    }
                });
                const otherRoles = others?.map(user => {
                    const m48Hours = 172800000;
                    const now = Date.now();
                    const createdDate = getMillis(user?.createdAt);
                    return {
                        ...user,
                        createdDate,
                        isModelling: false,
                        updatedDate: getMillis(user?.updatedAt),
                        isNew: now - createdDate <= m48Hours,
                    }
                });
                const allRoles = [...modellingRoles, ...otherRoles];
                setReg(allRoles);
            })
            .catch(err => console.log(err));
    }, []);

    return { registrations, jobs }
}

export default useRegistrations;
