import React from "react";

import Chip from '@mui/material/Chip';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { AuditionMap } from "../constants";
import { isCoupleSession } from "../utils";

const steps = Object.keys(AuditionMap)?.map(audition => {
    return {
        label: AuditionMap[audition].title,
        description: '',
    }
});

const AuditionOverview = ({ activeStep, isLastSlide, onAuditionComplete, moveNext, sessions }) => {
    return (
        <>
            <h4 style={{ paddingBottom: 12 }}>Audition Overview</h4>
            <Stepper activeStep={activeStep} orientation="vertical" style={{ flex: 1, overflowY: 'auto' }}>
                {sessions?.map((session, index) => (
                    <Step key={index}>
                        <StepLabel>
                            {session.title}
                        </StepLabel>
                        <StepContent>
                            <p>{session?.description}</p>
                            {index === activeStep && (
                                <Button
                                    variant="contained"
                                    disabled={(isCoupleSession(session?.key)) && !isLastSlide}
                                    sx={{ mt: 1, mr: 1 }}
                                    onClick={() => isLastSlide ? onAuditionComplete() : moveNext()}
                                >
                                    {isLastSlide ? 'Complete' : 'Submit & Next'}
                                </Button>
                            )}
                        </StepContent>
                    </Step>
                ))}
                {/* {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <p>{step.description}</p>
                            {index === activeStep && (
                                <Button
                                    variant="contained"
                                    disabled={(activeStep == 3 || activeStep == 4) && !isLastSlide}
                                    sx={{ mt: 1, mr: 1 }}
                                    onClick={() => isLastSlide ? onAuditionComplete() : moveNext()}
                                >
                                    {isLastSlide ? 'Complete' : 'Submit & Next'}
                                </Button>
                            )}
                        </StepContent>
                    </Step>
                ))} */}
            </Stepper>
        </>
    )
}

export default AuditionOverview;
