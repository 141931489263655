import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";

import { serverTimestamp } from "firebase/firestore";

import Footer from "./Footer";

const JobApplication = () => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();


    const onSuccess = (res) => {
        if (res.status === 200) {
            reset();
            toast.success('Exciting times! Your details have been submitted. Stay tuned!')
        }
        else 
            toast.error('Something went wrong, please try again!');
    }

    const onError = (err) => {
        toast.error('Something went wrong, please try again!');
    }

    const onSubmit = (data) => {
        setLoading(true);
        fetch('https:/immmodels.org/api/register-job', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...data, status: 0, createdAt: serverTimestamp() }) })
            .then(onSuccess)
            .catch(onError)
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Toaster toastOptions={{ duration: 10000, style: { fontSize: 14, fontWeight: 500 }, position: 'top-right' }} />
            <div style={{ paddingTop: 32 }}>
                <div id="submissions-section">
                    <h1 className="page-title" style={{ marginBottom: 30 }}>APPLYING TO IMM</h1>
                    <div className="submissions-container">
                        <div className="submissions-text">
                            Applications are open to anyone aged 16+ who would like to work with IMM and its related organisations. There are no restrictions by country or ethnicity.<br /><br />
                            Applicants we are interested in will usually receive a response within two weeks. If you do not hear back from us, you are welcome to reapply in 6 months’ time.
                        </div>

                        <div className="submissions-form">
                            <form id="applicationForm" onSubmit={handleSubmit(onSubmit)}>
                                {/* <input type="text" id="name" name="name" placeholder="NAME" /> */}
                                <input type="text" id="name" placeholder="NAME" {...register("name", { required: true })} />
                                <input type="text" id="gender" placeholder="GENDER" {...register("gender", { required: true })} />
                                <input type="text" id="pronoun" name="pronoun" placeholder="PRONOUNS" {...register("pronoun")} />
                                <input type="text" id="age" placeholder="AGE" {...register("age", { required: true })} />
                                <input type="text" id="dob" placeholder="DOB (dd/mm/yyyy)" {...register("dob", { required: true })} />

                                <div className="scouted-question" style={{ paddingTop: 12}}>
                                    <p>JOB ROLE</p>
                                </div>

                                <label for="jobId">Job ID (Role which you are applying for )</label>
                                <select name="jobId" id="jobId" placeholder="Select Job Id" {...register("jobId", { required: true })}>
                                    <option value="imm5210" selected>IMM5210 - Data Entry / Content Writing and Copywriting (Part-time - Work From Home)</option>
                                    <option value="imm5776">IMM5776 - Digital Marketing (Part-time - Work From Home)</option>
                                    <option value="imm5216">IMM5216 - Virtual Assistance (Part-time - Work From Home)</option>
                                    <option value="immt0012">IMMT0012 - Online Teaching and Tutoring (Part-time - Work From Home)</option>
                                    <option value="imm5331">IMM5331 - Virtual Human Resource Consultant (Part-time - Work From Home)</option>
                                    <option value="imm5025">IMM5025 - Front-end / Back-end Engineer (Part-time - Work From Home)</option>
                                    <option value="imm5021">IMM5021 - Virtual QA Engineer (Manual/Automation) (Part-time - Work From Home)</option>
                                    <option value="imm4222">IMM4222 - Virtual Support Engineer (Part-time - Work From Home)</option>
                                    <option value="imm5213">IMM5213 - Senior Go Developer (Full-time - Work From Home)</option>
                                    <option value="imm4773">IMM4773 - Lead Digital Artist (Full-time - Work From Home)</option>
                                    <option value="imm4214">IMM4214 - Content Writer (Part Time & Full-time - Work From Home)</option>
                                    <option value="imm4240">IMM4240 - Fashion Designer (Part Time & Full-time - Work From Home)</option>
                                </select>
                                
                                <div className="scouted-question martial-status">
                                    <p>MARTIAL STATUS?</p>
                                    <div className="status">
                                        <input type="radio" id="single" value="single" {...register("martialStatus", { required: true })} />
                                        <label htmlFor="single">SINGLE</label>
                                    </div>
                                    <div className="status">
                                        <input type="radio" id="married" value="married" {...register("martialStatus", { required: true })} />
                                        <label htmlFor="married">MARRIED</label>
                                    </div>
                                    <div className="status">
                                        <input type="radio" id="divorced" value="divorced" {...register("martialStatus", { required: true })} />
                                        <label htmlFor="divorced">DIVORCED</label>
                                    </div>
                                </div>

                                <div className="scouted-question" style={{ paddingTop: 12}}>
                                    <p>CONTACT INFORMATION</p>
                                </div>
                                <input type="text" id="country" placeholder="COUNTRY" {...register("country", { required: true })} />
                                <input type="text" id="city" placeholder="CITY" {...register("city", { required: true })} />
                                <input type="email" id="email" placeholder="EMAIL" {...register("email", { required: true })} />
                                <input type="text" id="phone" placeholder="PHONE" {...register("phone", { required: true })} />

                                <div className="scouted-question">
                                    <p>ARE YOU A FRESHER?</p>
                                    <div className="scouted">
                                        <input type="radio" id="scouted_1" {...register("fresher", { value: 'yes', required: true })} />
                                        <label htmlFor="scouted_1">YES</label>
                                    </div>
                                    <div className="scouted">
                                        <input type="radio" id="scouted_2" {...register("fresher", { value: 'no', required: true })} />
                                        <label htmlFor="scouted_2">NO</label>
                                    </div>
                                </div>

                                {/* <label style={{ fontSize: 10, display: 'block', marginTop: 12, fontWeight: 500, color: '#231f20', lineHeight: 1.4 }}>
                                    NOTE: If you don't have a modeling portfolio, please include around 15 to 20 photographs showcasing your modeling skills on Google Drive or iCloud Drive and share the link here (ensure the drive link is set to public and accessible).<br /><br />
                                </label> */}
                                <div className="scouted-question" style={{ paddingTop: 12}}>
                                    <p>EXPERIENCE INFO</p>
                                </div>
                                <input type="text" id="totalExperience" placeholder="TOTAL EXPERIENCE (In Years 0-15)" {...register("prevExperience", { required: true })} />
                                <input type="text" id="employer" placeholder="CURRENT EMPLOYER" {...register("employer", { required: true })} />
                                <input type="text" id="education" placeholder="HIGHEST DEGREE" {...register("education", { required: true })} />
                                <input type="text" id="portfolio" placeholder="LINKEDIN PORTFOLIO URL" {...register("portfolio", { required: true })} />
                                {/* <input type="text" id="instagram" placeholder="INSTAGRAM HANDLE" {...register("instagram")} /> */}
                                <input type="text" id="laptop" placeholder="LAPTOP BRAND AND OS" {...register("laptop", { required: true })} />
                                {/* <div className="scouted-question">
                                    <p>HAVE YOU BEEN SCOUTED BY US ?</p>
                                    <div className="scouted">
                                        <input type="radio" id="scouted_1" {...register("scouted", { value: 'yes', required: true })} />
                                        <label htmlFor="scouted_1">YES</label>
                                    </div>
                                    <div className="scouted">
                                        <input type="radio" id="scouted_2" {...register("scouted", { value: 'no', required: true })} />
                                        <label htmlFor="scouted_2">NO</label>
                                    </div>
                                </div> */}
                                {/* <div className="submissions-pictures">
                                    <div className="submissions-pictures-title">CHOOSE FILES:</div>
                                    <div className="submissions-pictures-inputs">
                                        <div className="upload-picture">
                                            <label htmlFor="image_1" id="file-label-1" className="label-file"><span>IMAGE</span></label>
                                            <input id="image_1" name="image_1" className="input-file" type="file" accept="image/*" data-max-size="3145728" />
                                        </div>
                                        <div className="upload-picture">
                                            <label htmlFor="image_2" id="file-label-2" className="label-file"><span>IMAGE</span></label>
                                            <input id="image_2" name="image_2" className="input-file" type="file" accept="image/*" data-max-size="3145728" />
                                        </div>
                                        <div className="upload-picture">
                                            <label htmlFor="image_3" id="file-label-3" className="label-file"><span>IMAGE</span></label>
                                            <input id="image_3" name="image_3" className="input-file" type="file" accept="image/*" data-max-size="3145728" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="submissions-validate" style={{ paddingTop: 32 }}>
                                    <div id="output">
                                        {errors && Object.keys(errors).length > 0 && <p role="alert">Fill in all the mandatory fields</p>}
                                    </div>
                                    <button id="submissions-submit" type="submit" disabled={loading} className="submit">{loading ? 'SUBMITTING...' : 'SUBMIT'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default JobApplication;