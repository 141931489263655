import React, { useEffect, useState } from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import ImageViewer from "react-simple-image-viewer";

import { useForm } from "react-hook-form";

import Footer from "../components/Footer";
import AuditionOverview from "../components/AuditionOverview";
import { AuditionIndexMap, AuditionMap } from "../constants";
import PrivateHeader from "../components/PrivateHeader";
import { useAuth } from "../providers/AuthProvider";
import { Navigate } from "react-router-dom";
import AuditionInfo from "../components/AuditionInfo";
import AuditionCapture from "./AuditionCapture";
import { useFirebase } from "../providers/FirebaseProvider";
import useMessages from "../hooks/useMessages";

export const AUDITION_KEY = 'imm_aud_key';
const MESSAGE_PATH = 'messages/fash_aud_chat_mode';
const AuditionInterview = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, },
    } = useForm();
    let api;

    // WebCam
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    const [showSlideshow, setShowSlideshow] = useState(false);
    // const [interviewKey, setInterviewKey] = useState(localStorage.getItem(AUDITION_KEY || 0));
    const interviewKey = localStorage.getItem(AUDITION_KEY) || 0; 
    const [isLastSlide, setIsLastSlide] = useState(false);

    const { listenToMessages, sendMessage } = useFirebase();
    const messages = useMessages(MESSAGE_PATH);
    console.log({messages})


    const onMessageReceived = (msg) => {
        console.log('Msg from server', msg);
    }

    useEffect(() => {
        listenToMessages(MESSAGE_PATH, onMessageReceived)
    }, [listenToMessages])

    const sessionName = AuditionIndexMap?.[interviewKey];
    const nowShowing = AuditionMap?.[sessionName];

    const { consent, reviewed } = useAuth();

    const onSubmit = (data) => {
        console.log(data);
    }

    const closeImageViewer = () => {

    }

    const onSlideChange = (section, origin, destination, direction, trigger) => {
        const { isLast } = destination || {};
        setIsLastSlide(isLast);
        setImgSrc(null);
    }

    const handleAuditionComplete = () => {
        if (interviewKey < 5) {
            localStorage.setItem(AUDITION_KEY, +interviewKey + 1);
            window.location.reload();
        }
    }

    const handleOnCapture = (wcIndex) => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc({
            ...imgSrc,
            [wcIndex]: imageSrc,
        });
    }

    if (!consent) {
        return <Navigate to='/audition/consent' />
    }

    if (!reviewed) {
        return <Navigate to='/audition/home' />
    }

    const sendMsg = () => {
        sendMessage(MESSAGE_PATH, 'Acknowledged!')
    }

    return (
        <div className="audition-interview-page" style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 32px)' }}>
            <div className="page-wrap" style={{ flexGrow: 1, paddingBottom: 12 }}>
                <PrivateHeader />
                <h1 className="page-info" style={{ marginTop: 12, paddingBottom: 8 }}>{nowShowing?.title}</h1>
                <span className="page-caption">Progress: 0{nowShowing.progess} of 0{nowShowing.steps}</span>

                <div className="panels">
                    <div className="panel-info panel-border">
                        <AuditionInfo />
                        {/* <AuditionOverview
                            activeStep={nowShowing?.progess - 1}
                            isLastSlide={isLastSlide}
                            onAuditionComplete={handleAuditionComplete}
                        /> */}
                    </div>
                    <div className="panel-audition">
                        <div className="consent-form">
                            <div className="consent-data">

                                <div className="consent-list">
                                    <div className="section">
                                        {showSlideshow && (
                                            <ImageViewer
                                                src={nowShowing?.images}
                                                currentIndex={0}
                                                onClose={closeImageViewer}
                                                disableScroll={false}
                                                backgroundStyle={{
                                                    backgroundColor: "rgba(0,0,0,0.9)"
                                                }}

                                                closeOnClickOutside={true}
                                            />
                                        )}
                                    </div>
                                    <ReactFullpage
                                        keyboardScrolling
                                        scrollingSpeed={600}
                                        waterEffect={false}
                                        loopHorizontal={false}
                                        responsiveHeight={400}
                                        onSlideLeave={onSlideChange}
                                        onScrollOverflow={() => console.log('On Overflow')}
                                        render={({ state, fullpageApi }) => {
                                            api = fullpageApi;
                                            fullpageApi?.setKeyboardScrolling(true, 'left, right');
                                            return (
                                                <ReactFullpage.Wrapper>
                                                    <div className="section" id="section0">
                                                        {nowShowing?.images?.map((image, idx) => (
                                                            <div key={idx} className="slide" data-anchor={`slide${idx + 1}`}>
                                                                <img className="img-poses" src={`https://imm-audition.s3.ap-south-1.amazonaws.com/${sessionName}/${image}`} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </ReactFullpage.Wrapper>
                                            );
                                        }}
                                    />
                                    <div className="wm-wrap">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel-actions panel-border">
                        <AuditionOverview
                            activeStep={nowShowing?.progess - 1}
                            isLastSlide={isLastSlide}
                            onAuditionComplete={handleAuditionComplete}
                        />
                        {/* <AuditionCapture imgSrc={imgSrc} onCapture={handleOnCapture} webcamRef={webcamRef} /> */}
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AuditionInterview;
