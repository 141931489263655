import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import ChatModule from "../components/chat/ChatModule";
import { useMeasure } from "../hooks/useMeasure";
import { useAuth } from "../providers/AuthProvider";
import useMessages from "../hooks/useMessages";
import { getMsgPath } from "../utils";
import { MsgType } from "../types";


const AuditionCapture = ({ imgSrc, webcamRef, audInfo, onCapture, audSessionId }) => {
    const [preview, setPreview] = useState(false);
    const [videoHeight, setVideoHeight] = useState(0);
    const [containerRef, { width, height }] = useMeasure();
    const [stopFeed, setStopFeed] = useState(false);

    const actions = useMessages(getMsgPath(audSessionId), MsgType.Action, true);

    const { user } = useAuth();

    const updateHeight = () => {
        setVideoHeight(containerRef?.current?.offsetHeight);
    }

    useEffect(() => {
        if (containerRef?.current) {
            setVideoHeight(containerRef?.current?.offsetHeight);
        }
    }, [containerRef]);

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    const handleCamFeed = (status) => {
        setStopFeed(status);
    }

    useEffect(() => {
        if (actions && actions?.message?.type === 'toggleCam') {
            setStopFeed(actions?.message?.action);
        }

        if (actions && actions?.message?.text === 'showPreview') {
            setPreview(true);
        }
        if (actions && actions?.message?.text === 'hidePreview') {
            setPreview(false);
        }
    }, [actions]);

    return (
        <>
            {true && (
                <div ref={containerRef} style={{ position: 'relative'}}>
                    <Webcam
                        style={{ width: 'w-full' }}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        itemRef=""
                    />
                    {stopFeed && (
                        <div className="h-full w-full bg-black flex items-center justify-center" style={{ position: 'absolute', top: 0, left: 0, zIndex: 11,}}>
                            <button className="text-white" onClick={() => handleCamFeed(false)}>RESUME CAM</button>
                        </div>
                    )}
                    <div className="p-1 text-right underline text-black w-full text-xs shadow-sm opacity-70" style={{ position: 'absolute', bottom: 0, zIndex: 10, }}>
                        <button className="underline text-red-700" onClick={() => handleCamFeed(true)}>STOP CAM</button>
                    </div>
                </div>
            )}
            {/* <div className="img-grid" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: 4 }}>
                <div className="img-capture-wrap">
                    {imgSrc && imgSrc?.[1] && <img width='100%' src={imgSrc?.[1]} />}
                </div>
                <div className="img-capture-wrap">
                    {imgSrc && imgSrc?.[2] && <img width='100%' src={imgSrc?.[2]} />}
                </div>
                <div className="img-capture-wrap">
                    {imgSrc && imgSrc?.[3] && <img width='100%' src={imgSrc?.[3]} />}
                </div>
            </div> */}
            {/* <div className="img-grid" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: 4 }}>
                <button type="button" onClick={() => onCapture(1)}>Capture 1</button>
                <button type="button" onClick={() => onCapture(2)}>Capture 2</button>
                <button type="button" onClick={() => onCapture(3)}>Capture 3</button>
            </div> */}
            <ChatModule offset={height} senderId={user?.sessionId} sessionId={audSessionId} infoMsg={audInfo?.message} actions={actions} />
        </>
    )
}

export default AuditionCapture;
